import { Component } from '@angular/core';
import { FirestoreService } from './shared/service/firestore/firestore.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { NotificationService } from './shared/service/notification/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'multikart-backend';

  constructor(
    private firestore: FirestoreService,
    private afAuth: AngularFireAuth,
    private router: Router,
    public notification: NotificationService,
  ){
    this.afAuth.authState.subscribe((userFirebase: any) => {
      if (userFirebase) {
        // console.log('auth getUserFirebase() : ', userFirebase.uid);
        this.firestore.get('users/' + userFirebase.uid).then(ref => {
          // console.log('auth get() : ', ref);
          ref.valueChanges().subscribe((userLocal: any) => {
            // this.notification.init(userLocal.uid);
            if (!userLocal.is_enabled === true) {
              // this.router.navigate(['/verification']);
            }
          });
        }).catch((e) => {
        });
      } 
    });
  }
}
