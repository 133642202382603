import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EventsService } from '../events/events.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import Swal from 'sweetalert2';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  audio: any;
  intervalSound: NodeJS.Timeout;
  token;

  constructor(
    private firestore: FirestoreService,
    private http: HttpClient,
    public events: EventsService,
    private afm: AngularFireMessaging,
    private functions: AngularFireFunctions,
    private auth: AuthService,
    private route: Router
  ) {
    this.events.subscribe('alert:sound', (data) => {
      // console.log(data)
      if (data['sound']) { 
        this.initSound();
        Swal.fire({
          title: 'Nueva Orden',
          toast: true, 
          position: 'bottom-end',
          background: '#FFF1BF', 
          showConfirmButton: false, 
          timer: 6e3, 
          timerProgressBar: true, 
          icon: 'info'
        }); 
      }
      else { 
        this.stopSound(); 
      }
    });
    if (this.audio) { this.audio = null; }
    this.audio = new Audio();
    this.audio.src = './../../assets/audio/hangouts_message.ogg';
    this.audio.load();
  }

  public init(uid): void {
    if (!!this.token == false) {
      // this.afm.requestToken.subscribe(
      //   (token) => {
      //     this.token = token;
      //     // console.log('notification: init(): requestToken: ' + this.token);
      //     this.firestore.setPushToken(uid, token);
      //     this.afm.messages.subscribe((data: any) => {
      //       // console.log(data);
      //       // console.log('notification: init(): message: ' + JSON.stringify(data));
      //       if (data.wasTapped) {
      //         //this.toast.showToast(data.title + ' ' + data.body);
      //         Swal.fire({
      //           title: data.title + ' ' + data.body,
      //           toast: true, position: 'top-end', 
      //           showConfirmButton: false, 
      //           timer: 6e3, 
      //           timerProgressBar: true, 
      //           icon: 'success'
      //         });
      //       } else {
      //         // this.toast.showToast(data.title + ' ' + data.body);
      //         Swal.fire({
      //           title: data.title + ' ' + data.body,
      //           toast: true, position: 'top-end', 
      //           showConfirmButton: false, 
      //           timer: 6e3, 
      //           timerProgressBar: true, 
      //           icon: 'success'
      //         });
      //       }
      //     }), ((e) => {
      //       console.log(e);
      //     });;
      //   },
      //   (error) => { console.error(error); }
      // );
    }
  }

  notify(nameFunction, paramsObject) {
    if (nameFunction != '' && paramsObject != null) {
      const callable = this.functions.httpsCallable(nameFunction);
      const obs = callable(paramsObject);
    }
  }


  public getPushTokenFromUsr(uid) {
    return new Promise((resolve, reject) => {
      this.firestore.getOne('users', uid).subscribe((data) => {
        // console.log(data);
        resolve(data);
      }), (function (error) {
        console.log(error);
        reject(false);
      });
    });
  }

  // Send a push notification given the pushToken, and the message.
  // Change the title, body, and data according to your requirements.
  public sendPush(token: string, title: string, body: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const postParams = {
        notification: {
          title: title,
          body: body,
          badge: 1,
          sound: 'default',
          click_action: 'FCM_PLUGIN_ACTIVITY',
          icon: 'ic_launcher'
        },
        data: {
          title: title,
          body: body,
          forceStart: 1,
        },
        to: token,
        priority: 'high',
        restricted_package_name: '',
        "apns": {
          "headers": {
            "apns-priority": "5"
          }
        },
      };

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'key=' + environment.gcmKey
      });

      const options = { headers: headers };

      this.http.post('https://fcm.googleapis.com/fcm/send', postParams, options).subscribe(response => {
        resolve(response);
      }, err => {
        reject(err);
      });
    });
  }

  initSound() {
    let this_ = this;
    this.audio.play();
    this.audio.loop = true;

    clearInterval(this.intervalSound);
    this.intervalSound = setInterval(function () {
      //console.log('event!!!')
      this_.events.publish('alert:changeColor', { change: true });
    }, 1000);
  }

  stopSound() {
    if (this.audio) {
      this.audio.pause();
      //this.audio = null;
    }
    clearInterval(this.intervalSound);
  }

  public sendMail(data) {
    return new Promise((resolve, reject) => {
        const url = `https://mail.yelou503.com/?to=${data.to}&from=${data.from}&subject=${data.subject}&html=${data.html}`;
        // console.log('url->' + url);

        fetch(url, {
          body: JSON.stringify(data),
          headers: {
            'dataType': 'json',
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'POST',
          redirect: 'follow'

        }).then(response => {
            if (response.status === 200) {
                // console.log('reolver ok-> ' + response.text());
                // console.log(`ROL ${data.rol} MAIL OK enviado a ${data.email}`);
                resolve(response.text());
            }
            else {
                // console.log(`ROL ${data.rol} MAIL NO enviado a ${data.email}`);
                resolve('resolver ()false');
            }
        }).catch(error => {
            // console.error('error-> ' + error);
        });
    });
  }
  public getPushToken(uid) {
    return new Promise((resolve, reject) => {
      this.firestore.getOne('user_push_tokens', uid).subscribe((data) => {
        resolve(data);
      }), (function (error) {
        // console.log(error);
        resolve(null);
      });
    });
  }


  public async NavAccessUser(path: string) {
    return new Promise(resolve => {
      let access;
      this.firestore.getWhere('nav_menu_permissions','path','/' + path).subscribe(res => {
        this.auth.getUser2().then(user => {
          // console.log("user: ",user)
          if (user.is_super === false) {
            this.firestore.getOnePath('nav_menu_permissions/'+res[0].id+'/users/'+user.uid).subscribe(res1 => {
              // console.log("nav_menu_permissions user: ",res1)
              if (res1 !== undefined) {
                if (res1.hasOwnProperty('view') && res1.view === true) {
                  access = true;
                  resolve({access,...res1});
                }else{
                  access = false;
                  Swal.close();
                  Swal.fire(
                    'No tienes acceso a esta página',
                    '',
                    'info'
                  );
                  this.route.navigate(['/dashboard/default']);
                  resolve(access);
                }
              }else{
                access = false;
                Swal.close();
                Swal.fire(
                  'No tienes acceso a esta página',
                  '',
                  'info'
                );
                this.route.navigate(['/dashboard/default']);
                resolve(access);
              }
            });
          }else{
            access = true;
            resolve({access,admin:true});
          }
        })
      });
    });
  }

}
