<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-4">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details text-center">
                        <img [src]="photoURL" alt=""
                            style="object-fit: cover; border: 3px solid gray; max-width: 100px; max-height: 100px; min-width: 100px; min-height: 100px;"
                            class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                        <h5 class="f-w-600 mb-0">{{displayName}}</h5>
                        <span>{{user.email}}</span>
                        <div>
                            <button class="btn btn-outline-primary btn-icon-text" *ngIf="!edit" (click)="edit = !edit">
                                <app-feather-icons icon="edit" style="zoom: 0.8;"></app-feather-icons>
                                <!-- Editar Perfil -->
                            </button>
                            <div class="row" *ngIf="edit">
                                <div class="col-12 col-md-12 col-xl-12"
                                    style="margin-top: 5px; display: flex; justify-content: center;">
                                    <button class="btn btn-outline-success btn-icon-text" (click)="sendForm()">
                                        <app-feather-icons icon="save" style="zoom: 0.8;"></app-feather-icons>
                                        <!-- Guardar -->
                                    </button>
                                    <button class="btn btn-outline-danger btn-icon-text" (click)="edit = !edit" style="margin-left: 5px;">
                                        <app-feather-icons icon="x-circle" style="zoom: 0.8;"></app-feather-icons>
                                        <!-- Cancelar -->
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-8">
            <div class="card tab2-card">
                <div class="card-body">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink><i class="me-2" data-feather="user"></i>Datos Generales</a>
                            <ng-template ngbNavContent>
                                <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                                    aria-labelledby="top-profile-tab">
                                    <h5 class="f-w-600"> Profile</h5>
                                    <div class="row">
                                        <div class="col-12 col-md-6 col-xl-6 mb-4">
                                            <h6 class="card-title">Correo del administrador</h6>
                                            <p class="tx-1 text-muted"><strong>{{user.email}}</strong></p>
                                        </div>
                                        <div class="col-12 col-md-6 col-xl-6 mb-4">
                                            <h6 class="card-title">Nombre del administrador</h6>
                                            <input type="text" class="form-control" id="exampleInputText1"
                                                [disabled]="!edit" placeholder="Ingresar Nombre"
                                                [(ngModel)]="user.firstName">
                                        </div>
                                        <div class="col-12 col-md-6 col-xl-6 mb-4">
                                            <h6 class="card-title">Apellido del administrador</h6>
                                            <input type="text" class="form-control" id="exampleInputText1"
                                                [disabled]="!edit" placeholder="Ingresar Nombre"
                                                [(ngModel)]="user.lastName">
                                        </div>
                                        <div class="col-12 col-md-6 col-xl-6 mb-4">
                                            <h6 class="card-title">Direccion</h6>
                                            <input type="text" class="form-control" id="exampleInputText1"
                                                [disabled]="!edit" placeholder="Ingresar Nombre"
                                                [(ngModel)]="user.direction">
                                        </div>
                                        <div class="col-12 col-md-6 col-xl-6 mb-4">
                                            <h6 class="card-title">Teléfono de contacto</h6>
                                            <input type="text" class="form-control" id="exampleInputText1"
                                                [disabled]="!edit" placeholder="Ingresar Nombre"
                                                [(ngModel)]="user.phone">
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2" *ngIf="edit">
                            <a ngbNavLink class="d-flex align-items-center"><i data-feather="image"
                                    class="me-2"></i>Imagen</a>
                            <ng-template ngbNavContent>
                                <div class="account-setting">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="mb-3">
                                                <h6 class="card-title mb-0">Edición de Foto de Perfil</h6>
                                                <input class="form-control" type="file" id="cropperImageUpload"
                                                    (change)="handleFileInput($event)">
                                            </div>
                                            <div>
                                                <angular-cropper #angularCropper [cropperOptions]="config"
                                                    [imageUrl]="imageUrl"></angular-cropper>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center flex-wrap">
                                                <div class="d-flex align-items-center flex-wrap me-2 mt-3">
                                                    <button class="btn btn-primary crop mb-2 mb-md-0"
                                                    (click)="cropImage()">Recortar</button>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center flex-wrap">
                                                <div class="d-flex align-items-center flex-wrap me-2 mt-3">
                                                    <button class="btn btn-outline-dark crop mb-2 mb-md-0"
                                                        (click)="cropImageSave()">Recortar y Guardar</button>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center flex-wrap">
                                                <div class="d-flex align-items-center flex-wrap me-2 mt-3">
                                                    <a href="" class="btn btn-outline-primary download"
                                                        download="imageName.png">Descargar imagen recortada</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 ms-auto">
                                            <h6 class="text-muted mb-3">Imagen recortada: </h6>
                                            <img class="w-100 cropped-img mt-2" src="{{resultImage}}" alt="">
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>

                    <!-- <ngb-tabset class="tab-coupon profile-tabs">
                        <ngb-tab>
                            <ng-template ngbTabTitle>
                                <app-feather-icons [icon]="'user'"></app-feather-icons>Profile</ng-template>
                            <ng-template ngbTabContent>

                            </ng-template>
                        </ngb-tab>
                        <ngb-tab>
                            <ng-template ngbTabTitle>
                                <i data-feather="settings"></i> Contact</ng-template>
                            <ng-template ngbTabContent>
                               
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->