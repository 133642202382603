<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
            <div class="card o-hidden widget-cards">
                <div class="bg-warning card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'briefcase'" class="font-warning"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8">
                            <span class="m-0"><strong>Comercios</strong></span>
                            <h3 class="mb-0"><span class="counter">{{totalCommerce}}</span><small></small></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
            <div class="card o-hidden widget-cards">
                <div class="bg-danger card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'users'" class="font-danger"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0"><strong>Clientes</strong></span>
                            <h3 class="mb-0"><span class="counter">{{totalUser}}</span><small></small></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
            <div class="card o-hidden widget-cards">
                <div class="bg-success card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'truck'" class="font-success"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0"><strong>Yets</strong></span>
                            <h3 class="mb-0"><span class="counter">{{totalYet}}</span><small></small></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
            <div class="card o-hidden widget-cards">
                <div class="bg-dark card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <img src="assets/img/yet-24.svg" alt="" style="width: 25px;" class="font-dark">
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0"><strong>Pedidos aceptados por
                                    yet</strong></span>
                            <h3 *ngIf="show_counts_label" class="mb-0"><span class="counter">{{orderAcepted}}</span><small></small></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
            <div class="card o-hidden widget-cards">
                <div class="bg-info card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <img src="assets/img/moto-1.svg" alt="" style="width: 25px;" class="font-info">
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0"><strong>Pedidos disponibles para
                                    yets</strong></span>
                            <h3 *ngIf="show_counts_label" class="mb-0"><span class="counter">{{orderForYet}}</span><small></small></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
            <div class="card o-hidden widget-cards">
                <div class="bg-secondary card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <img src="assets/img/yet-25.svg" alt="" style="width: 25px;" class="font-secondary">
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0"><strong>Pedidos en camino por el
                                    yet</strong></span>
                            <h3 *ngIf="show_counts_label" class="mb-0"><span class="counter">{{orderInWay}}</span><small></small></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-md-12">
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <div class="card height-equal">
                <div class="card-header" style="padding-bottom: 0px;">
                    <div class="row">
                        <div class="col-12 col-md-4 col-xl-6" style="margin-bottom: 10px; display: flex;">
                            <h5>Pedidos</h5>
                        </div>
                        <div class="col-12 col-md-8 col-xl-6"
                            style="display: flex; justify-content: flex-end; zoom: 0.8;"
                            *ngIf="commerce_data.length !== 0">
                            <div class="btn-group mb-3 mb-md-0" role="group" aria-label="Basic example">
                                <button type="button" style="padding-left: 10px; padding-right: 10px;"
                                    [class]="btn_date.today.class" (click)="changedTypeSearch2(1)">Día</button>
                                <button type="button" style="padding-left: 10px; padding-right: 10px;"
                                    [class]="btn_date.week.class" (click)="changedTypeSearch2(2)">Semana</button>
                                <button type="button" style="padding-left: 10px; padding-right: 10px;"
                                    [class]="btn_date.month.class" (click)="changedTypeSearch2(3)">Mes</button>
                                <button type="button" style="padding-left: 10px; padding-right: 10px;"
                                    [class]="btn_date.year.class" (click)="changedTypeSearch2(4)">Año</button>
                            </div>
                            <a href="javascript:void(0)" style="margin-left: 5px;"
                                (click)="show_filter_date_chart = !show_filter_date_chart"
                                class="btn btn-primary m-r-10">
                                <app-feather-icons [icon]="'filter'"></app-feather-icons>
                            </a>
                        </div>
                        <div *ngIf="show_filter_date_chart" class="col-12 col-md-12 col-xl-12"
                            style="animation:alternate; display: flex; justify-content: flex-end;">
                            <div class="row">
                                <div class="col-12 col-md-5 col-xl-5">
                                    <h6 class="card-title" style="margin-bottom: 5px; margin-top: 5px;">Inicio</h6>
                                    <form class="d-flex">
                                        <div class="mb-2">
                                            <div class="input-group">
                                                <input class="form-control" placeholder="yyyy-mm-dd" name="dp"
                                                    [(ngModel)]="selectedDate1" ngbDatepicker #d1="ngbDatepicker">
                                                <button class="input-group-text" type="button" (click)="d1.toggle()">
                                                    <i class="feather icon-calendar icon-md text-muted"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-12 col-md-5 col-xl-5">
                                    <h6 class="card-title" style="margin-bottom: 5px; margin-top: 5px;">Fin</h6>
                                    <form class="d-flex">
                                        <div class="mb-2">
                                            <div class="input-group">
                                                <input class="form-control" placeholder="yyyy-mm-dd" name="dp"
                                                    [(ngModel)]="selectedDate2" ngbDatepicker #d2="ngbDatepicker">
                                                <button class="input-group-text" type="button" (click)="d2.toggle()">
                                                    <i class="feather icon-calendar icon-md text-muted"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-12 col-md-2 col-xl-2">
                                    <button type="button" (click)="SearchByDate()" class="btn btn-outline-primary"
                                        style="margin-top: 25px;">Buscar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body" style="padding-top: 0px;">
                    <div class="row" style="margin-top: 20px;">
                        <div class="media-body col-12 col-md-4 col-xl-4">
                            <span class="m-0"><strong>De: {{date_from}}</strong></span>
                            <br>
                            <span class="m-0"><strong>Hasta: {{date_up_to}}</strong></span>
                        </div>
                        <div class="media-body col-6 col-md-4 col-xl-4"><span class="m-0">Pedidos Completados</span>
                            <h3 *ngIf="refresh && count_orders_date !== 0" class="mb-0">
                                <app-feather-icons [icon]="'shopping-bag'" class="font-warning"
                                    style="margin-right: 5px;"></app-feather-icons>
                                <span class="counter">{{count_orders_date}}</span><small></small><span
                                    style="font-size:medium;"> pedidos</span>
                            </h3>
                        </div>
                        <div class="media-body col-6 col-md-4 col-xl-4"><span class="m-0">Ventas Realizadas</span>
                            <h3 *ngIf="refresh && count_orders_complete_date !== 0" class="mb-0">
                                <app-feather-icons [icon]="'dollar-sign'" class="font-warning"></app-feather-icons>
                                <span class="counter">{{ToFixed(count_orders_complete_date)}}</span><small></small>
                            </h3>
                        </div>
                        <div class="media-body col-12" *ngIf="!refresh">
                            <div class="loading-container">
                                <div class="loading-spinner"></div>
                            </div>
                        </div>
                        <div class="media-body col-12" *ngIf="show_message_no_data"
                            style="min-height: 300px; height: 100%; display: flex; align-items: center; justify-content: center;">
                            <div class="card-header">
                                <h5 style="color: #E0B006;">Sin ordenes</h5>
                            </div>
                        </div>
                    </div>
                    <div class="market-chart" *ngIf="refresh && !show_message_no_data">
                        <apx-chart class="board-chart ct-golden-section" [series]="revenueChartOptions.series"
                            [chart]="revenueChartOptions.chart" [colors]="revenueChartOptions.colors"
                            [grid]="revenueChartOptions.grid" [xaxis]="revenueChartOptions.xaxis"
                            [yaxis]="revenueChartOptions.yaxis" [markers]="revenueChartOptions.markers"
                            [stroke]="revenueChartOptions.stroke"></apx-chart>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Pedidos Programados</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table overflowTable" *ngIf="listProgrammed.length != 0">
                        <ngx-datatable class="bootstrap" [rows]="listProgrammed" [scrollbarH]="false"
                            style="min-width: 800px;" [loadingIndicator]="loadingIndicator"
                            [columnMode]="ColumnMode.force" [footerHeight]="50" [limit]="10" rowHeight="auto">
                            <ngx-datatable-column name="# de Orden" [cellTemplate]="Order">
                                <ng-template #Order let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    <a [routerLink]="'/order-show/' + value.uid">{{value.uid}}</a>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Yet nombre" prop="yet_uid"></ngx-datatable-column>
                            <ngx-datatable-column name="Tipo de entrega" [cellTemplate]="TypeDelivery">
                                <ng-template #TypeDelivery let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    {{value.is_delivery ? 'Delivery' : 'Recoger en comercio'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Forma de pago" [cellTemplate]="methodPayment">
                                <ng-template #methodPayment let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    {{value.is_card ? 'Tarjeta' : 'Efectivo'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Monto de pedido" prop="total"></ngx-datatable-column>
                            <ngx-datatable-column name="Local" prop="commerce_name"></ngx-datatable-column>
                            <ngx-datatable-column name="Fecha Programada" prop="order_schedule_format">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Fecha" prop="created_at_formatted"></ngx-datatable-column>
                            <ngx-datatable-column name="Hora" prop="created_at_formatted2"></ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                    <div *ngIf="listProgrammed.length == 0">
                        <h6 style="text-align: center;"> <strong>Sin Pedidos Programados</strong> </h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Pedidos Creados</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table overflowTable" *ngIf="listCreate.length != 0">
                        <!-- <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th># de orden</th>
                                    <th>yet id</th>
                                    <th>yet nombre</th>
                                    <th>Tipo de entrega</th>
                                    <th>Forma de pago</th>
                                    <th>Monto de pedido</th>
                                    <th>Local</th>
                                    <th>Fecha</th>
                                    <th>Hora</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of listCreate;let i = index;">
                                    <td>
                                        <a [routerLink]="'/order-show/' + item.uid">{{item.uid}}</a>
                                    </td>
                                    <td><small>{{item.yet_uid}}</small></td>
                                    <td>{{item.yet_name}}</td>
                                    <td>{{item.is_delivery ? 'Delivery' : 'Recoger en comercio'}}</td>
                                    <td>{{item.is_card ? 'Tarjeta' : 'Efectivo'}}</td>
                                    <td>${{item.total}}</td>
                                    <td>{{item.commerce_name}}</td>
                                    <td>{{item.created_at_formatted}}</td>
                                    <td>{{item.created_at_formatted2}}</td>
                                </tr>
                                <tr *ngIf="listCreate.length === 0">
                                    <td colspan="9" style="text-align: center;"> <strong>Sin Pedidos Creadas</strong> </td>
                                </tr>
                            </tbody>
                        </table> -->
                        <!-- <a [routerLink]="'/orders'" class="btn btn-primary">Ver todas las ordenes</a> -->
                        <ngx-datatable class="bootstrap" [rows]="listCreate" [scrollbarH]="false"
                            style="min-width: 800px;" [loadingIndicator]="loadingIndicator"
                            [columnMode]="ColumnMode.force" [footerHeight]="50" [limit]="10" rowHeight="auto">
                            <ngx-datatable-column name="# de Orden" [cellTemplate]="Order">
                                <ng-template #Order let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    <a [routerLink]="'/order-show/' + value.uid">{{value.uid}}</a>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Yet nombre" prop="yet_uid"></ngx-datatable-column>
                            <ngx-datatable-column name="Tipo de entrega" [cellTemplate]="TypeDelivery">
                                <ng-template #TypeDelivery let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    {{value.is_delivery ? 'Delivery' : 'Recoger en comercio'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Forma de pago" [cellTemplate]="methodPayment">
                                <ng-template #methodPayment let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    {{value.is_card ? 'Tarjeta' : 'Efectivo'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Monto de pedido" prop="total"></ngx-datatable-column>
                            <ngx-datatable-column name="Local" prop="commerce_name"></ngx-datatable-column>
                            <ngx-datatable-column name="Fecha" prop="created_at_formatted"></ngx-datatable-column>
                            <ngx-datatable-column name="Hora" prop="created_at_formatted2"></ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                    <div *ngIf="listCreate.length == 0">
                        <h6 style="text-align: center;"> <strong>Sin Pedidos Creadas</strong> </h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Pedidos Aceptados</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table overflowTable" *ngIf="ListPreparing.length != 0">
                        <ngx-datatable class="bootstrap" [rows]="ListPreparing" [scrollbarH]="false"
                            style="min-width: 800px;" [loadingIndicator]="loadingIndicator"
                            [columnMode]="ColumnMode.force" [footerHeight]="50" [limit]="10" rowHeight="auto">
                            <ngx-datatable-column name="# de Orden" [cellTemplate]="Order">
                                <ng-template #Order let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    <a [routerLink]="'/order-show/' + value.uid">{{value.uid}}</a>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Yet nombre" prop="yet_uid"></ngx-datatable-column>
                            <ngx-datatable-column name="Tipo de entrega" [cellTemplate]="TypeDelivery">
                                <ng-template #TypeDelivery let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    {{value.is_delivery ? 'Delivery' : 'Recoger en comercio'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Forma de pago" [cellTemplate]="methodPayment">
                                <ng-template #methodPayment let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    {{value.is_card ? 'Tarjeta' : 'Efectivo'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Monto de pedido" prop="total"></ngx-datatable-column>
                            <ngx-datatable-column name="Local" prop="commerce_name"></ngx-datatable-column>
                            <ngx-datatable-column name="Fecha" prop="created_at_formatted"></ngx-datatable-column>
                            <ngx-datatable-column name="Hora" prop="created_at_formatted2"></ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                    <div *ngIf="ListPreparing.length == 0">
                        <h6 style="text-align: center;"> <strong>Sin Pedidos Aceptados</strong> </h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Pedidos Disponibles Para Recoger</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table overflowTable" *ngIf="listAvailableNotYet.length != 0">
                        <ngx-datatable class="bootstrap" [rows]="listAvailableNotYet" [scrollbarH]="false"
                            style="min-width: 800px;" [loadingIndicator]="loadingIndicator"
                            [columnMode]="ColumnMode.force" [footerHeight]="50" [limit]="10" rowHeight="auto">
                            <ngx-datatable-column name="# de Orden" [cellTemplate]="Order">
                                <ng-template #Order let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    <a [routerLink]="'/order-show/' + value.uid">{{value.uid}}</a>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Yet nombre" prop="yet_uid"></ngx-datatable-column>
                            <ngx-datatable-column name="Tipo de entrega" [cellTemplate]="TypeDelivery">
                                <ng-template #TypeDelivery let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    {{value.is_delivery ? 'Delivery' : 'Recoger en comercio'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Forma de pago" [cellTemplate]="methodPayment">
                                <ng-template #methodPayment let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    {{value.is_card ? 'Tarjeta' : 'Efectivo'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Monto de pedido" prop="total"></ngx-datatable-column>
                            <ngx-datatable-column name="Local" prop="commerce_name"></ngx-datatable-column>
                            <ngx-datatable-column name="Fecha" prop="created_at_formatted"></ngx-datatable-column>
                            <ngx-datatable-column name="Hora" prop="created_at_formatted2"></ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                    <div *ngIf="listAvailableNotYet.length == 0">
                        <h6 style="text-align: center;"> <strong>Sin Pedidos Disponibles Para Recoger</strong> </h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Pedidos Disponibles Para Yet</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table overflowTable" *ngIf="listAvailable.length != 0">
                        <ngx-datatable class="bootstrap" [rows]="listAvailable" [scrollbarH]="false"
                            style="min-width: 800px;" [loadingIndicator]="loadingIndicator"
                            [columnMode]="ColumnMode.force" [footerHeight]="50" [limit]="10" rowHeight="auto">
                            <ngx-datatable-column name="# de Orden" [cellTemplate]="Order">
                                <ng-template #Order let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    <a [routerLink]="'/order-show/' + value.uid">{{value.uid}}</a>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Yet nombre" prop="yet_uid"></ngx-datatable-column>
                            <ngx-datatable-column name="Tipo de entrega" [cellTemplate]="TypeDelivery">
                                <ng-template #TypeDelivery let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    {{value.is_delivery ? 'Delivery' : 'Recoger en comercio'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Forma de pago" [cellTemplate]="methodPayment">
                                <ng-template #methodPayment let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    {{value.is_card ? 'Tarjeta' : 'Efectivo'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Monto de pedido" prop="total"></ngx-datatable-column>
                            <ngx-datatable-column name="Local" prop="commerce_name"></ngx-datatable-column>
                            <ngx-datatable-column name="Fecha" prop="created_at_formatted"></ngx-datatable-column>
                            <ngx-datatable-column name="Hora" prop="created_at_formatted2"></ngx-datatable-column>
                        </ngx-datatable>
                        <!-- <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th># de orden</th>
                                    <th>yet id</th>
                                    <th>yet nombre</th>
                                    <th>Tipo de entrega</th>
                                    <th>Forma de pago</th>
                                    <th>Monto de pedido</th>
                                    <th>Local</th>
                                    <th>Fecha</th>
                                    <th>Hora</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of listAvailable;let i = index;">
                                    <td>
                                        <a [routerLink]="'/order-show/' + item.uid">{{item.uid}}</a>
                                    </td>
                                    <td><small>{{item.yet_uid}}</small></td>
                                    <td>{{item.yet_name}}</td>
                                    <td>{{item.is_delivery ? 'Delivery' : 'Recoger en comercio'}}</td>
                                    <td>{{item.is_card ? 'Tarjeta' : 'Efectivo'}}</td>
                                    <td>${{item.total}}</td>
                                    <td>{{item.commerce_name}}</td>
                                    <td>{{item.created_at_formatted}}</td>
                                    <td>{{item.created_at_formatted2}}</td>
                                </tr>
                                <tr *ngIf="listAvailable.length === 0">
                                    <td colspan="9" style="text-align: center;"> <strong>Sin Pedidos Disponibles Para
                                            Yet</strong> </td>
                                </tr>
                            </tbody>
                        </table> -->
                        <!-- <a [routerLink]="'/orders'" class="btn btn-primary">Ver todas las ordenes</a> -->
                    </div>
                    <div *ngIf="listAvailable.length == 0">
                        <h6 style="text-align: center;"> <strong>Sin Pedidos Disponibles Para Yet</strong> </h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Pedidos Aceptados Por Yet</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table overflowTable" *ngIf="listAcepted.length != 0">
                        <ngx-datatable class="bootstrap" [rows]="listAcepted" [scrollbarH]="false"
                            style="min-width: 800px;" [loadingIndicator]="loadingIndicator"
                            [columnMode]="ColumnMode.force" [footerHeight]="50" [limit]="10" rowHeight="auto">
                            <ngx-datatable-column name="# de Orden" [cellTemplate]="Order">
                                <ng-template #Order let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    <a [routerLink]="'/order-show/' + value.uid">{{value.uid}}</a>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Yet nombre" prop="yet_uid"></ngx-datatable-column>
                            <ngx-datatable-column name="Tipo de entrega" [cellTemplate]="TypeDelivery">
                                <ng-template #TypeDelivery let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    {{value.is_delivery ? 'Delivery' : 'Recoger en comercio'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Forma de pago" [cellTemplate]="methodPayment">
                                <ng-template #methodPayment let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    {{value.is_card ? 'Tarjeta' : 'Efectivo'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Monto de pedido" prop="total"></ngx-datatable-column>
                            <ngx-datatable-column name="Local" prop="commerce_name"></ngx-datatable-column>
                            <ngx-datatable-column name="Fecha" prop="created_at_formatted"></ngx-datatable-column>
                            <ngx-datatable-column name="Hora" prop="created_at_formatted2"></ngx-datatable-column>
                        </ngx-datatable>
                        <!-- <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th># de orden</th>
                                    <th>yet id</th>
                                    <th>yet nombre</th>
                                    <th>Tipo de entrega</th>
                                    <th>Forma de pago</th>
                                    <th>Monto de pedido</th>
                                    <th>Local</th>
                                    <th>Fecha</th>
                                    <th>Hora</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of listAcepted;let i = index;">
                                    <td>
                                        <a [routerLink]="'/order-show/' + item.uid">{{item.uid}}</a>
                                    </td>
                                    <td><small>{{item.yet_uid}}</small></td>
                                    <td>{{item.yet_name}}</td>
                                    <td>{{item.is_delivery ? 'Delivery' : 'Recoger en comercio'}}</td>
                                    <td>{{item.is_card ? 'Tarjeta' : 'Efectivo'}}</td>
                                    <td>${{item.total}}</td>
                                    <td>{{item.commerce_name}}</td>
                                    <td>{{item.created_at_formatted}}</td>
                                    <td>{{item.created_at_formatted2}}</td>
                                </tr>
                                <tr *ngIf="listAcepted.length === 0">
                                    <td colspan="9" style="text-align: center;"> <strong>Sin Pedidos Aceptados Por
                                            Yet</strong> </td>
                                </tr>
                            </tbody>
                        </table> -->
                        <!-- <a [routerLink]="'/orders'" class="btn btn-primary">Ver todas las ordenes</a> -->
                    </div>
                    <div *ngIf="listAcepted.length == 0">
                        <h6 style="text-align: center;"> <strong>Sin Pedidos Aceptados Por Yet</strong> </h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Pedidos En Camino</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table overflowTable" *ngIf="listInWay.length != 0">
                        <ngx-datatable class="bootstrap" [rows]="listInWay" [scrollbarH]="false"
                            style="min-width: 800px;" [loadingIndicator]="loadingIndicator"
                            [columnMode]="ColumnMode.force" [footerHeight]="50" [limit]="10" rowHeight="auto">
                            <ngx-datatable-column name="# de Orden" [cellTemplate]="Order">
                                <ng-template #Order let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    <a [routerLink]="'/order-show/' + value.uid">{{value.uid}}</a>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Yet nombre" prop="yet_uid"></ngx-datatable-column>
                            <ngx-datatable-column name="Tipo de entrega" [cellTemplate]="TypeDelivery">
                                <ng-template #TypeDelivery let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    {{value.is_delivery ? 'Delivery' : 'Recoger en comercio'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Forma de pago" [cellTemplate]="methodPayment">
                                <ng-template #methodPayment let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    {{value.is_card ? 'Tarjeta' : 'Efectivo'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Monto de pedido" prop="total"></ngx-datatable-column>
                            <ngx-datatable-column name="Local" prop="commerce_name"></ngx-datatable-column>
                            <ngx-datatable-column name="Fecha" prop="created_at_formatted"></ngx-datatable-column>
                            <ngx-datatable-column name="Hora" prop="created_at_formatted2"></ngx-datatable-column>
                        </ngx-datatable>
                        <!-- <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th># de orden</th>
                                    <th>yet id</th>
                                    <th>yet nombre</th>
                                    <th>Tipo de entrega</th>
                                    <th>Forma de pago</th>
                                    <th>Monto de pedido</th>
                                    <th>Local</th>
                                    <th>Fecha</th>
                                    <th>Hora</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of listInWay;let i = index;">
                                    <td>
                                        <a [routerLink]="'/order-show/' + item.uid">{{item.uid}}</a>
                                    </td>
                                    <td><small>{{item.yet_uid}}</small></td>
                                    <td>{{item.yet_name}}</td>
                                    <td>{{item.is_delivery ? 'Delivery' : 'Recoger en comercio'}}</td>
                                    <td>{{item.is_card ? 'Tarjeta' : 'Efectivo'}}</td>
                                    <td>${{item.total}}</td>
                                    <td>{{item.commerce_name}}</td>
                                    <td>{{item.created_at_formatted}}</td>
                                    <td>{{item.created_at_formatted2}}</td>
                                </tr>
                                <tr *ngIf="listInWay.length === 0">
                                    <td colspan="9" style="text-align: center;"> <strong>Sin Pedidos En Camino</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table> -->
                        <!-- <a [routerLink]="'/orders'" class="btn btn-primary">Ver todas las ordenes</a> -->
                    </div>
                    <div *ngIf="listInWay.length == 0">
                        <h6 style="text-align: center;"> <strong>Sin Pedidos En Camino</strong> </h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Pedidos Creados Y Entregados Ultimos 2 Días</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table overflowTable" *ngIf="listDelivered.length != 0">
                        <ngx-datatable class="bootstrap" [rows]="listDelivered" [scrollbarH]="false"
                            style="min-width: 800px;" [loadingIndicator]="loadingIndicator"
                            [columnMode]="ColumnMode.force" [footerHeight]="50" [limit]="10" rowHeight="auto">
                            <ngx-datatable-column name="# de Orden" [cellTemplate]="Order">
                                <ng-template #Order let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    <a [routerLink]="'/order-show/' + value.uid">{{value.uid}}</a>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Yet nombre" prop="yet_uid"></ngx-datatable-column>
                            <ngx-datatable-column name="Tipo de entrega" [cellTemplate]="TypeDelivery">
                                <ng-template #TypeDelivery let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    {{value.is_delivery ? 'Delivery' : 'Recoger en comercio'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Forma de pago" [cellTemplate]="methodPayment">
                                <ng-template #methodPayment let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    {{value.is_card ? 'Tarjeta' : 'Efectivo'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Monto de pedido" prop="total"></ngx-datatable-column>
                            <ngx-datatable-column name="Local" prop="commerce_name"></ngx-datatable-column>
                            <ngx-datatable-column name="Fecha" prop="created_at_formatted"></ngx-datatable-column>
                            <ngx-datatable-column name="Hora" prop="created_at_formatted2"></ngx-datatable-column>
                        </ngx-datatable>
                        <!-- <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th># de orden</th>
                                    <th>Nombre del yet</th>
                                    <th>Tipo de entrega</th>
                                    <th>Forma de pago</th>
                                    <th>Monto del pedido</th>
                                    <th>Local</th>
                                    <th>Fecha</th>
                                    <th>Hora</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of listDelivered;let i = index;">
                                    <td>
                                        <a [routerLink]="'/order-show/' + item.uid">{{item.uid}}</a>
                                    </td>
                                    <td>{{item.yet_name}}</td>
                                    <td>{{item.is_delivery ? 'Delivery' : 'Recoger en comercio'}}</td>
                                    <td>{{item.is_card ? 'Tarjeta' : 'Efectivo'}}</td>
                                    <td>${{item.total}}</td>
                                    <td>{{item.commerce_name}}</td>
                                    <td>{{item.created_at_formatted}}</td>
                                    <td>{{item.created_at_formatted2}}</td>
                                </tr>
                                <tr *ngIf="listDelivered.length === 0">
                                    <td colspan="9" style="text-align: center;"> <strong>Sin Pedidos Creados Y
                                            Entregados Ultimos 2 Días</strong> </td>
                                </tr>
                            </tbody>
                        </table> -->
                        <!-- <a [routerLink]="'/orders'" class="btn btn-primary">Ver todas las ordenes</a> -->
                    </div>
                    <div *ngIf="listDelivered.length == 0">
                        <h6 style="text-align: center;"> <strong>Sin Pedidos Creados Y
                                Entregados Ultimos 2 Días</strong> </h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Pedidos Creados Y Cancelados Ultimos 2 Días</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table overflowTable" *ngIf="listCancelated.length != 0">
                        <ngx-datatable class="bootstrap" [rows]="listCancelated" [scrollbarH]="false"
                            style="min-width: 800px;" [loadingIndicator]="loadingIndicator"
                            [columnMode]="ColumnMode.force" [footerHeight]="50" [limit]="10" rowHeight="auto">
                            <ngx-datatable-column name="# de Orden" [cellTemplate]="Order">
                                <ng-template #Order let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    <a [routerLink]="'/order-show/' + value.uid">{{value.uid}}</a>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Yet nombre" prop="yet_uid"></ngx-datatable-column>
                            <ngx-datatable-column name="Tipo de entrega" [cellTemplate]="TypeDelivery">
                                <ng-template #TypeDelivery let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    {{value.is_delivery ? 'Delivery' : 'Recoger en comercio'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Forma de pago" [cellTemplate]="methodPayment">
                                <ng-template #methodPayment let-row="row" let-value="row"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    {{value.is_card ? 'Tarjeta' : 'Efectivo'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Monto de pedido" prop="total"></ngx-datatable-column>
                            <ngx-datatable-column name="Local" prop="commerce_name"></ngx-datatable-column>
                            <ngx-datatable-column name="Fecha" prop="created_at_formatted"></ngx-datatable-column>
                            <ngx-datatable-column name="Hora" prop="created_at_formatted2"></ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                    <div *ngIf="listCancelated.length == 0">
                        <h6 style="text-align: center;"> <strong>Sin Pedidos Creados Y
                                Cancelados Ultimos 2 Días</strong> </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->