import { Component, OnInit } from '@angular/core';
import * as chartData from '../../shared/data/chart';
import { doughnutData, pieData } from '../../shared/data/chart';
import { Subscription } from 'rxjs';
import { User } from 'src/app/shared/models';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { FirestoreservicesService } from 'src/app/shared/service/firestoreservices/firestoreservices.service';
import { FirestoreService } from 'src/app/shared/service/firestore/firestore.service';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { element } from 'protractor';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public doughnutData = doughnutData;
  public pieData = pieData;

  revenueChartOptions: any = {};
  obj = {
    primary: "#E0B006",
    blue: "#0044FF",
    wine: "#581845",
    brown: "#5D4D4D",
    lime: "#CDFF00",
    purple: "#6700FF",
    secondary: "#7987a1",
    success: "#05a34a",
    info: "#66d1d1",
    warning: "#fbbc06",
    danger: "#ff3366",
    light: "#e9ecef",
    dark: "#060c17",
    muted: "#7987a1",
    gridBorder: "rgba(77, 138, 240, .15)",
    bodyColor: "#000",
    cardBg: "#fff",
    fontFamily: "'Roboto', Helvetica, sans-serif"
  }
  user = new User();
  count_orders_date: number = 0;
  count_orders_complete_date: number = 0;
  date_from: string = "";
  date_up_to: string = "";

  count_orders: number = 0;
  count_orders_complete: number = 0;
  schedule = [];
  commerces_names = [];
  commerces_status = [];
  latest_orders = [];
  orders: any = [];
  prueba = [];
  day = "";
  btn_date = {
    today: {
      class: "btn btn-outline-primary"
    },
    week: {
      class: "btn btn-outline-primary"
    },
    month: {
      class: "btn btn-primary"
    },
    year: {
      class: "btn btn-outline-primary"
    },
  }
  refresh: boolean = true;
  totalCommerce = 0;
  commerce_data: any = [];

  sub1: Subscription;
  sub2: Subscription;
  sub3: Subscription;
  sub4: Subscription;
  sub5: Subscription;
  sub6: Subscription;
  sub7: Subscription;
  sub8: Subscription;
  sub9: Subscription;
  sub10: Subscription;
  sub11: Subscription;

  listCreate = [];
  listProgrammed = [];
  listAvailable = [];
  listAvailableNotYet = [];
  listAcepted = [];
  listInWay = [];
  listDelivered = [];
  listCancelated = [];
  listYets = [];
  ListPreparing = [];

  allComerce = [];

  orderForYet = 0;
  orderAcepted = 0;
  orderInWay = 0;

  totalYet = 0;
  totalUser = 0;
  partnersZones = [];
  show_counts_label: boolean = true;
  show_message_no_data: boolean = false;
  show_filter_date_chart: boolean = false;

  selectedDate1: NgbDateStruct;
  selectedDate2: NgbDateStruct;
  loadingIndicator = true;
  reorderable = true;
  ColumnMode = ColumnMode;

  constructor(
    public auth: AuthService,
    private firestore: FirestoreService,
    private router: Router,
    private calendar: NgbCalendar,
  ) {
    Object.assign(this, { doughnutData, pieData })
  }
  // events
  public chartClicked(e: any): void {
  }
  public chartHovered(e: any): void {
  }

  ngOnInit() {
    if (this.sub1) { this.sub1.unsubscribe(); }
    this.auth.getUser2().then(res0 => {
      // console.log("getUser2: ",res0)

      this.user = res0;
      this.getUsers();
      this.getRevenueChartOptions(this.obj);
      this.getPartnerFromZone().then(res => {
        this.loadOrders();
      });
    }).catch(err => {
      console.log("err: ", err)
      this.router.navigate(['/auth/login']);
    });
  }

  getPartnerFromZone() {
    return new Promise((resolve, reject) => {
      this.firestore.getAll('commerces').subscribe((response) => {
        let activeCommerce = [];
        activeCommerce = response.filter(item => item.is_visible === true);
        // console.log("activeCommerce: ", activeCommerce);
        activeCommerce.forEach(element => {
          this.partnersZones.push(element.uid);
        });
        //this.partnersZones 
        // console.log("==== PARTNER UIDs ====");
        // console.log(this.partnersZones);
        resolve(this.partnersZones);
      })
    })
  }

  getUsers() {
    let yets = [];
    let user = [];
    this.firestore.getAll('users').subscribe((response) => {
      yets = response.filter(item => item.is_driver && item.is_enabled_to_drive);
      user = response.filter(item => !item.is_driver);
      // console.log("LENGTH OF YET IS");
      // console.log(yets.length);
      this.totalYet = yets.length;
      // console.log("LENGTH OF USER");
      // console.log(user.length);
      this.totalUser = user.length;
    });
  }

  formatDate(date) {
    var day = new Date(date.seconds * 1000);
    var format = day.getFullYear() + '/' + ((day.getMonth() + 1 < 10) ? '0' + (day.getMonth() + 1) : day.getMonth() + 1) + '/' + ((day.getDate() < 10) ? '0' + day.getDate() : day.getDate()) + ' ' + new Date(date.seconds * 1000).toLocaleTimeString("es-ES");
    return format;
  }

  getProfitPerMonth() {
    // let date;
    // date = new Date();
    // let month = date.getMonth();
    // let year = date.getFullYear();
    // let day = date.getDate();
    // let indexDate1;
    // indexDate1 =  new Date(Number(year), Number(month) - 1, Number(day) + 1);
    // const options = {year: 'numeric', month: 'short', day: 'numeric'};

    // this.profitsPerMonth_date = indexDate1.toLocaleDateString('es-ES', options) + " - " + date.toLocaleDateString('es-ES', options);

    // this.firestore.getWhere3('orders', 'manager_uid', this.user.uid , 'orders_status_uid', 'Entregada', indexDate1, "").subscribe(data => {
    //   data.forEach((element: { amount: any; }) => {
    //     // console.log(element.amount);
    //     this.profitsPerMonth += Number(element.amount);
    //   });
    //   this.profitsPerMonth = Number(this.profitsPerMonth.toFixed(2));
    //   // console.log(`getProfitPerMonth = ${this.profitsPerMonth}`);
    // });
    // // console.log(`getProfitPerMonth = ${this.profitsPerMonth}`);
  }

  getProfitPerDay() {
    // let date = new Date();

    // let month = date.getMonth();
    // let year = date.getFullYear();
    // let day = date.getDate();
    // let start;
    // let end
    // start =  new Date(Number(year), Number(month), Number(day) - 1,23,59);
    // end =  new Date(Number(year), Number(month), Number(day) - 2,0,0);
    // // console.log("start: ",start);
    // // console.log("end: ",end);
    // const options = {year: 'numeric', month: 'short', day: 'numeric'};

    // this.profitsPerDay_date = end.toLocaleDateString('es-ES', options) + " - " + start.toLocaleDateString('es-ES', options);

    // this.firestore.getWhere4('orders', 'manager_uid', this.user.uid , 'orders_status_uid', 'Entregada', end, start).subscribe(data => {
    //   // console.log('profitsPerDay: ', data);
    //   data.forEach((element: { amount: any; }) => {
    //     this.profitsPerDay += Number(element.amount);
    //   });
    //   this.profitsPerDay = Number(this.profitsPerDay.toFixed(2));
    //   // console.log(`getProfitPerDay = ${this.profitsPerDay}`);
    // });
  }

  SearchByDate() {
    let indexDate1 = new Date(this.selectedDate1.year, this.selectedDate1.month - 1, this.selectedDate1.day, 0, 0, 0);
    let indexDate2 = new Date(this.selectedDate2.year, this.selectedDate2.month - 1, this.selectedDate2.day, 23, 59, 59);
    this.count_orders_date = 0;
    this.count_orders_complete_date = 0;
    this.refresh = false;
    this.show_message_no_data = false;

    this.firestore.getAll('commerces').subscribe((response) => {
      //  console.log("commerces: ",response)
      let activeCommerce = [];
      activeCommerce = response.filter(item => item.is_visible === true && item.zone && item.zone !== '');
      // console.log("activeCommerce: ",activeCommerce)
      this.totalCommerce = activeCommerce.length;
      this.count_orders_date = 0;
      this.count_orders_complete_date = 0;
      this.refresh = false;
      this.show_message_no_data = false;


      setTimeout(() => {
        // console.log("indexDate1", indexDate1);
        // console.log("indexDate2", indexDate2);

        let dataChart_labels: any = [];
        let countOrders = 0;
        let countOrdersSales = 0;

        activeCommerce.forEach(async (uid_manager, index1) => {
          // console.log("uid_manager", uid_manager);
          await this.firestore.getWhere4('orders', 'manager_uid', uid_manager.manager_user_uid, 'orders_status_uid', 'Entregada', indexDate1, indexDate2).subscribe((res2) => {

            let dataChart = [];
            dataChart.length = 0;

            if (res2.length !== 0) {
              // console.log("uid_manager", uid_manager, " res2", res2);
              let groupedDates = {};
              countOrders += res2.length;

              res2.forEach((orders_, index) => {
                let initTime = new Date(Date.UTC(70, 0, 1, 0, 0, 0, 0));
                initTime.setSeconds(orders_['created_at']['seconds']);
                countOrdersSales += orders_.total;

                let format = initTime.toDateString().split(" ");
                let format_;

                format_ = format[1] + " " + format[2] + " " + format[3];

                if (!groupedDates[format_]) {
                  groupedDates[format_] = { x: format_, y: 0, count: 0 };
                }

                groupedDates[format_].y += 1;
                groupedDates[format_].count += Number(orders_.total);
                // groupedDates[format_].z = "$ " + (groupedDates[format_].count).toFixed(2);

                if (index === (res2.length - 1)) {
                  let day_format: any = new Date(indexDate1.getFullYear(), (indexDate1.getMonth()), indexDate1.getDate(), 23, 59, 59);
                  let hour_format_1: any = new Date(indexDate2.getFullYear(), (indexDate2.getMonth()), indexDate2.getDate(), 0, 0, 0);
                  let options;

                  options = { year: 'numeric', month: 'short', day: 'numeric' };

                  let format_1 = day_format.toLocaleDateString('es-ES', options);
                  let format_2 = hour_format_1.toLocaleDateString('es-ES', options);
                  this.date_from = format_1;
                  this.date_up_to = format_2;
                  // console.log("groupedDates: ", groupedDates);

                  // Agregar el objeto al array dataChart_labels sin modificarlo
                  let dataChartEntry = {
                    name: "pedidos de " + uid_manager.name,
                    data: Object.values(groupedDates) // Obtener los valores del objeto groupedDates
                  };
                  dataChart_labels.push(dataChartEntry);

                  if (index1 === (activeCommerce.length - 1)) {
                    let longestArray = 0;
                    let longestArray_: any;
                    dataChart_labels.forEach(element => {
                      if (element.data.length > longestArray) {
                        longestArray = element.data.length;
                        longestArray_ = element;
                      }
                    });
                    // console.log("longestArray_: ", longestArray_);

                    dataChart_labels.forEach(element1 => {
                      longestArray_.data.forEach(element3 => {
                        if (element1.name !== longestArray_.name) {
                          let prueba = element1.data.find(item => item.x === element3.x);
                          // console.log("prueba: ", prueba);
                          if (!prueba) {
                            element1.data.push({ x: element3.x, y: 0, count: 0 });
                          }
                        }
                      });
                      element1.data = element1.data.sort(function (a, b) {
                        var keyA = new Date(a.x), keyB = new Date(b.x);
                        if (keyA > keyB) return 1;
                        if (keyA < keyB) return -1;
                        return 0;
                      });
                    });
                    // console.log("dataChart_labels: ", dataChart_labels);
                    this.count_orders_date = countOrders;
                    this.count_orders_complete_date = Number(this.ToFixed(countOrdersSales));
                    this.refresh = true;

                    this.showChart(this.obj, dataChart_labels);
                  }
                }
              });

            } else {
              if (index1 === (activeCommerce.length - 1)) {
                let longestArray = 0;
                let longestArray_: any;
                dataChart_labels.forEach(element => {
                  if (element.data.length > longestArray) {
                    longestArray = element.data.length;
                    longestArray_ = element
                  }
                });
                // console.log("longestArray_: ", longestArray_);

                dataChart_labels.forEach(element1 => {
                  longestArray_.data.forEach(element3 => {
                    if (element1.name !== longestArray_.name) {
                      let prueba = element1.data.find(item => item.x === element3.x);
                      // console.log("prueba: ", prueba);
                      if (!prueba) {
                        element1.data.push({ x: element3.x, y: 0, count: 0 });
                      }
                    }
                  });
                  element1.data = element1.data.sort(function (a, b) {
                    var keyA = new Date(a.x), keyB = new Date(b.x);
                    if (keyA > keyB) return 1;
                    if (keyA < keyB) return -1;
                    return 0;
                  });
                });
                // console.log("dataChart_labels~: ", dataChart_labels);
                this.count_orders_date = countOrders;
                this.count_orders_complete_date = Number(this.ToFixed(countOrdersSales));
                this.refresh = true;

                this.showChart(this.obj, dataChart_labels);
              }
            }
          });
        });
      }, 300);
    }), ((e) => { console.log(e) });
  }

  getCommerces() {
    this.firestore.getWhere('commerces', 'manager_user_uid', this.user.uid).subscribe(res => {
      let sched = [];
      let date = new Date();
      let commerce = [];
      this.prueba = [];
      this.schedule = [];
      // console.log(res);
      res.forEach((element: any) => {
        // console.log(element);
        if (element.schedule != undefined && Array.isArray(element.schedule)) {
          if (element.close_automatically) {
            sched = element.schedule;
            commerce = element;
            this.schedule.push(sched.filter(item => item.index == date.getDay()));

            this.commerces_names.push(element.name);
            this.commerces_status.push(element.is_available);
            this.day = this.schedule[0][0].day

            this.prueba.push(this.schedule[0]);
            // console.log(`COMMERCE NAME ${element.name}`);
            // console.log(this.schedule);
            // console.log(sched);
            setInterval(this.clock, 1000);
          } else {
            // console.log("Schedule is not available");
          }
        }
      });
      // console.log(commerce);
    });
  }

  clock() {
    let time = new Date();
    let hour = time.getHours();
    let min = time.getMinutes();
    let sec = time.getSeconds();

    let currentTime;
    currentTime = ((hour < 10) ? "0" + hour : hour) + ":" + ((min < 10) ? "0" + min : min) + ":" + ((sec < 10) ? "0" + sec : sec);

    if (document.getElementById("h4")) {
      document.getElementById("h4").innerHTML = currentTime;
    }
  }

  ToFixed(number: number) {
    return Number(number).toFixed(2);
  }

  loadOrders() {
    if (this.sub8) { this.sub8.unsubscribe(); }
    this.sub8 = this.firestore.getWhere('orders', 'orders_status_uid', 'Creada').subscribe((res0) => {
      this.show_counts_label = false;
      // console.log("Creada: ",res0)
      if (res0.length > 0) {
        res0 = res0.sort(function (a, b) {
          var keyA = a.created_at.seconds, keyB = b.created_at.seconds;
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });
        res0.forEach(item => {
          var unixTime = item.created_at.seconds;
          var date = new Date(unixTime * 1000);
          item.created_at_formatted = date.toLocaleDateString("es-ES");
          item.created_at_formatted2 = date.toLocaleTimeString("es-ES");
        });
        this.listCreate = res0;
      }
      else { this.listCreate = []; }

      setTimeout(() => {
        this.show_counts_label = true;
      }, 500);
    });

    if (this.sub2) { this.sub2.unsubscribe(); }
    this.sub2 = this.firestore.getWhere('orders', 'orders_status_uid', 'Disponible').subscribe((res0) => {
      this.show_counts_label = false;
      //this.firestore.getWhere('users','uid',)
      // console.log("Disponible: ",res0)

      // console.log(res0);
      if (res0.length > 0) {
        res0 = res0.sort(function (a, b) {
          var keyA = a.created_at.seconds, keyB = b.created_at.seconds;
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });
        res0.forEach(item => {
          var unixTime = item.created_at.seconds;
          var date = new Date(unixTime * 1000);
          item.created_at_formatted = date.toLocaleDateString("es-ES");
          item.created_at_formatted2 = date.toLocaleTimeString("es-ES");
        });

        res0.forEach((item, index) => {
          if (this.partnersZones.indexOf(item.commerce_uid) > -1 && item.is_delivery === true) {
            this.listAvailable.push(item);
          }else if (this.partnersZones.indexOf(item.commerce_uid) > -1 && item.is_delivery === false) {
            this.listAvailableNotYet.push(item);
          }
        });
        // console.log(this.listAvailable);
        //this.listAvailable = res0;
        this.orderForYet = this.listAvailable.length;
      }
      else { this.listAvailable = []; }
      setTimeout(() => {
        this.show_counts_label = true;
      }, 500);
    });

    //pedidos aceptados
    if (this.sub3) { this.sub3.unsubscribe(); }
    this.sub3 = this.firestore.getWhere('orders', 'orders_status_uid', 'Aceptada').subscribe((res0) => {
      // console.log("Aceptada: ",res0)
      this.show_counts_label = false;
      if (res0.length > 0) {
        res0 = res0.sort(function (a, b) {
          var keyA = a.created_at.seconds, keyB = b.created_at.seconds;
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });
        res0.forEach(item => {
          var unixTime = item.created_at.seconds;
          var date = new Date(unixTime * 1000);
          item.created_at_formatted = date.toLocaleDateString("es-ES");
          item.created_at_formatted2 = date.toLocaleTimeString("es-ES");
        });

        res0.forEach((item, index) => {
          if (this.partnersZones.indexOf(item.commerce_uid) > -1 && item.is_delivery === true) {
            this.listAcepted.push(item);
          }
        });
        //this.listAcepted = res0;
        this.orderAcepted = this.listAcepted.length;
      }
      else { this.listAcepted = []; }
      setTimeout(() => {
        this.show_counts_label = true;
      }, 500);
    });

    //pedidos en camino
    if (this.sub4) { this.sub4.unsubscribe(); }
    this.sub4 = this.firestore.getWhere('orders', 'orders_status_uid', 'En camino').subscribe((res0) => {
      // console.log("En camino: ",res0)
      this.show_counts_label = false;
      if (res0.length > 0) {
        res0 = res0.sort(function (a, b) {
          var keyA = a.created_at.seconds, keyB = b.created_at.seconds;
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });
        res0.forEach(item => {
          var unixTime = item.created_at.seconds;
          var date = new Date(unixTime * 1000);
          item.created_at_formatted = date.toLocaleDateString("es-ES");
          item.created_at_formatted2 = date.toLocaleTimeString("es-ES");
        });

        res0.forEach((item, index) => {
          if (this.partnersZones.indexOf(item.commerce_uid) > -1) {
            this.listInWay.push(item);
          }
        });
        //this.listInWay = res0;
        this.orderInWay = this.listInWay.length;
      }
      else { this.listInWay = []; }
      setTimeout(() => {
        this.show_counts_label = true;
      }, 500);
    });

    //pedidos entregados
    if (this.sub5) { this.sub5.unsubscribe(); }
    let now = new Date();
    let indexDate1 = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
    let indexDate2 = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    // console.log(indexDate1)
    // console.log(indexDate2)
    this.sub5 = this.firestore.getWhereWithRange('orders', 'orders_status_uid', 'Entregada', 'created_at', indexDate1, indexDate2).subscribe((res0) => {
      // console.log("Entregada: ", res0)
      this.show_counts_label = false;

      if (res0.length > 0) {
        res0 = res0.sort(function (a, b) {
          var keyA = a.created_at.seconds, keyB = b.created_at.seconds;
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });
        res0.forEach(item => {
          var unixTime = item.created_at.seconds;
          var date = new Date(unixTime * 1000);
          item.created_at_formatted = date.toLocaleDateString("es-ES");
          item.created_at_formatted2 = date.toLocaleTimeString("es-ES");
        });

        res0.forEach((item, index) => {
          // console.log("item.manager_uid: ", this.partnersZones.indexOf(item.manager_uid))
          if (this.partnersZones.indexOf(item.commerce_uid) > -1) {
            this.listDelivered.push(item);
          }
        });

        //this.listDelivered = res0;
        // console.log("-------------LISTDELIVERY-------------------");
        // console.log(this.listDelivered);
      }
      else { this.listDelivered = []; }
      setTimeout(() => {
        this.show_counts_label = true;
      }, 500);
    });

    //pedidos cancelados
    if (this.sub6) { this.sub6.unsubscribe(); }
    this.sub6 = this.firestore.getWhereWithRange('orders', 'orders_status_uid', 'Cancelada', 'created_at', indexDate1, indexDate2).subscribe((res0) => {
      // console.log(res0)
      this.show_counts_label = false;

      if (res0.length > 0) {
        res0 = res0.sort(function (a, b) {
          var keyA = a.created_at.seconds, keyB = b.created_at.seconds;
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });
        res0.forEach(item => {
          var unixTime = item.created_at.seconds;
          var date = new Date(unixTime * 1000);
          item.created_at_formatted = date.toLocaleDateString("es-ES");
          item.created_at_formatted2 = date.toLocaleTimeString("es-ES");
        });

        res0.forEach((item, index) => {
          if (this.partnersZones.indexOf(item.commerce_uid) > -1) {
            this.listCancelated.push(item);
          }
        });
        //this.listCancelated = res0;
      }
      else { this.listCancelated = []; }
      setTimeout(() => {
        this.show_counts_label = true;
      }, 500);
    });

    if (this.sub7) { this.sub7.unsubscribe(); }
    this.sub7 = this.firestore.getWhere('users', 'is_gps_on', true).subscribe((res0) => {
      // console.log(res0)}
      this.show_counts_label = false;

      if (res0.length > 0) {
        res0 = res0.sort(function (a, b) {
          var keyA = a.created_at.seconds, keyB = b.created_at.seconds;
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });
        res0.forEach(item => {
          var unixTime = item.created_at.seconds;
          var date = new Date(unixTime * 1000);
          item.created_at_formatted = date.toLocaleDateString("es-ES");
          item.created_at_formatted2 = date.toLocaleTimeString("es-ES");
        });
        this.listYets = res0;
      }
      else { this.listYets = []; }
      setTimeout(() => {
        this.show_counts_label = true;
      }, 500);
    });

    if (this.sub10) { this.sub10.unsubscribe(); }
    this.sub10 = this.firestore.getWhere('orders', 'orders_status_uid', 'Preparando').subscribe((res0) => {
      // console.log(res0)
      if (res0.length > 0) {
        res0 = res0.sort(function (a, b) {
          var keyA = a.created_at.seconds, keyB = b.created_at.seconds;
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });
        res0.forEach(item => {
          var unixTime = item.created_at.seconds;
          var date = new Date(unixTime * 1000);
          item.created_at_formatted = date.toLocaleDateString("es-ES");
          item.created_at_formatted2 = date.toLocaleTimeString("es-ES");
        });
        this.ListPreparing = res0;
      }
      else { this.ListPreparing = []; }
    })

    // this.sub5 = this.firestore.getWhereWithRange('orders', 'orders_status_uid', 'Entregada', 'created_at', indexDate1, indexDate2).subscribe((res0) => {
    //   // console.log("Entregada: ", res0)
    //   this.show_counts_label = false;

    //   if (res0.length > 0) {
    //     res0 = res0.sort(function (a, b) {
    //       var keyA = a.created_at.seconds, keyB = b.created_at.seconds;
    //       if (keyA < keyB) return 1;
    //       if (keyA > keyB) return -1;
    //       return 0;
    //     });
    //     res0.forEach(item => {
    //       var unixTime = item.created_at.seconds;
    //       var date = new Date(unixTime * 1000);
    //       item.created_at_formatted = date.toLocaleDateString("es-ES");
    //       item.created_at_formatted2 = date.toLocaleTimeString("es-ES");
    //     });

    //     res0.forEach((item, index) => {
    //       // console.log("item.manager_uid: ", this.partnersZones.indexOf(item.manager_uid))
    //       if (this.partnersZones.indexOf(item.commerce_uid) > -1) {
    //         this.listDelivered.push(item);
    //       }
    //     });

    //     //this.listDelivered = res0;
    //     // console.log("-------------LISTDELIVERY-------------------");
    //     // console.log(this.listDelivered);
    //   }
    //   else { this.listDelivered = []; }
    //   setTimeout(() => {
    //     this.show_counts_label = true;
    //   }, 500);
    // });

    if (this.sub11) { this.sub10.unsubscribe(); }
    this.sub11 = this.firestore.getWhere('orders', 'orders_status_uid', 'Programada').subscribe((res0) => {
      // console.log(res0)
      if (res0.length > 0) {
        res0 = res0.sort(function (a, b) {
          var keyA = a.created_at.seconds, keyB = b.created_at.seconds;
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });
        res0.forEach(item => {
          var unixTime = item.created_at.seconds;
          var date = new Date(unixTime * 1000);
          item.created_at_formatted = date.toLocaleDateString("es-ES");
          item.created_at_formatted2 = date.toLocaleTimeString("es-ES");
        });
        this.listProgrammed = res0;
      }
      else { this.listProgrammed = []; }
    })
  }

  changedTypeSearch2(type) {
    const today = this.calendar.getToday();
    let num_days;
    let limit_day;
    this.count_orders_date = 0;
    this.count_orders_complete_date = 0;
    this.refresh = false;
    this.show_message_no_data = false;
    let indexDate1;
    let indexDate2;

    switch (type) {
      case 1:
        this.btn_date.today.class = "btn btn-primary";
        this.btn_date.week.class = "btn btn-outline-primary";
        this.btn_date.month.class = "btn btn-outline-primary";
        this.btn_date.year.class = "btn btn-outline-primary";
        num_days = this.calendar.getToday();
        limit_day = this.calendar.getToday();
        indexDate1 = new Date(num_days.year, num_days.month - 1, num_days.day, 0, 0);
        indexDate2 = new Date(limit_day.year, limit_day.month - 1, limit_day.day, 23, 59);

        break;
      case 2:
        this.btn_date.today.class = "btn btn-outline-primary";
        this.btn_date.week.class = "btn btn-primary";
        this.btn_date.month.class = "btn btn-outline-primary";
        this.btn_date.year.class = "btn btn-outline-primary";
        num_days = this.calendar.getPrev(today, 'd', 7);
        limit_day = this.calendar.getToday();
        indexDate1 = new Date(num_days.year, num_days.month - 1, num_days.day, 0, 0);
        indexDate2 = new Date(limit_day.year, limit_day.month - 1, limit_day.day, 23, 59);

        break;
      case 3:
        this.btn_date.today.class = "btn btn-outline-primary";
        this.btn_date.week.class = "btn btn-outline-primary";
        this.btn_date.month.class = "btn btn-primary";
        this.btn_date.year.class = "btn btn-outline-primary";
        num_days = this.calendar.getPrev(today, 'm', 1);
        limit_day = this.calendar.getToday();
        indexDate1 = new Date(num_days.year, num_days.month - 1, num_days.day, 0, 0);
        indexDate2 = new Date(limit_day.year, limit_day.month - 1, limit_day.day, 23, 59);

        break;
      case 4:
        this.btn_date.today.class = "btn btn-outline-primary";
        this.btn_date.week.class = "btn btn-outline-primary";
        this.btn_date.month.class = "btn btn-outline-primary";
        this.btn_date.year.class = "btn btn-primary";
        num_days = this.calendar.getPrev(today, 'y', 1);
        limit_day = this.calendar.getToday();
        indexDate1 = new Date(num_days.year, num_days.month - 1, num_days.day, 0, 0);
        indexDate2 = new Date(limit_day.year, limit_day.month - 1, limit_day.day, 23, 59);

        break;
      default:
        break;
    }

    setTimeout(() => {
      // console.log("indexDate1", indexDate1);
      // console.log("indexDate2", indexDate2);
      let dataChart_labels: any = [];
      let countOrders = 0;
      let countOrdersSales = 0;

      this.commerce_data.forEach(async (uid_manager, index1) => {
        // console.log("uid_manager", uid_manager);
        await this.firestore.getWhere4('orders', 'manager_uid', uid_manager.manager_user_uid, 'orders_status_uid', 'Entregada', indexDate1, indexDate2).subscribe((res2) => {

          let dataChart = [];
          dataChart.length = 0;

          if (res2.length !== 0) {
            // console.log("uid_manager", uid_manager, " res2", res2);
            let groupedDates = {};
            countOrders += res2.length;

            res2.forEach((orders_, index) => {
              let initTime = new Date(Date.UTC(70, 0, 1, 0, 0, 0, 0));
              initTime.setSeconds(orders_['created_at']['seconds']);
              countOrdersSales += orders_.total;

              let format = initTime.toDateString().split(" ");
              let format_;

              if (type === 4) {
                format_ = format[1] + " " + format[3];
              } else if (type === 3) {
                format_ = format[1] + " " + format[2] + " " + format[3];
              } else if (type === 1) {
                let otherFormatHour = initTime.getHours();
                format_ = format[1] + " " + format[2] + " " + format[3] + " " + ((otherFormatHour < 10) ? '0' + otherFormatHour + ':00:00' : otherFormatHour + ':00:00');
              } else {
                format_ = format[0] + " " + format[1] + " " + format[2] + " " + format[3];
              }

              if (!groupedDates[format_]) {
                groupedDates[format_] = { x: format_, y: 0, count: 0 };
              }

              groupedDates[format_].y += 1;
              groupedDates[format_].count += Number(orders_.total);
              // groupedDates[format_].z = "$ " + (groupedDates[format_].count).toFixed(2);

              if (index === (res2.length - 1)) {
                let day_format: any = new Date(indexDate1.getFullYear(), (indexDate1.getMonth()), indexDate1.getDate(), 23, 59, 59);
                let hour_format_1: any = new Date(indexDate2.getFullYear(), (indexDate2.getMonth()), indexDate2.getDate(), 0, 0, 0);
                let options;

                if (type === 1) {
                  options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
                } else {
                  options = { year: 'numeric', month: 'short', day: 'numeric' };
                }

                let format_1 = day_format.toLocaleDateString('es-ES', options);
                let format_2 = hour_format_1.toLocaleDateString('es-ES', options);
                this.date_from = format_1;
                this.date_up_to = format_2;
                // console.log("groupedDates: ", groupedDates);

                // Agregar el objeto al array dataChart_labels sin modificarlo
                let dataChartEntry = {
                  name: "pedidos de " + uid_manager.name,
                  data: Object.values(groupedDates) // Obtener los valores del objeto groupedDates
                };
                dataChart_labels.push(dataChartEntry);

                if (index1 === (this.commerce_data.length - 1)) {
                  let longestArray = 0;
                  let longestArray_: any;
                  dataChart_labels.forEach(element => {
                    if (element.data.length > longestArray) {
                      longestArray = element.data.length;
                      longestArray_ = element;
                    }
                  });
                  // console.log("longestArray_: ", longestArray_);

                  dataChart_labels.forEach(element1 => {
                    longestArray_.data.forEach(element3 => {
                      if (element1.name !== longestArray_.name) {
                        let prueba = element1.data.find(item => item.x === element3.x);
                        // console.log("prueba: ", prueba);
                        if (!prueba) {
                          element1.data.push({ x: element3.x, y: 0, count: 0 });
                        }
                      }
                    });
                    element1.data = element1.data.sort(function (a, b) {
                      var keyA = new Date(a.x), keyB = new Date(b.x);
                      if (keyA > keyB) return 1;
                      if (keyA < keyB) return -1;
                      return 0;
                    });
                  });
                  // console.log("dataChart_labels: ", dataChart_labels);
                  this.count_orders_date = countOrders;
                  this.count_orders_complete_date = Number(this.ToFixed(countOrdersSales));
                  this.refresh = true;

                  this.showChart(this.obj, dataChart_labels);
                }
              }
            });

          } else {
            if (index1 === (this.commerce_data.length - 1)) {
              let longestArray = 0;
              let longestArray_: any;
              dataChart_labels.forEach(element => {
                if (element.data.length > longestArray) {
                  longestArray = element.data.length;
                  longestArray_ = element
                }
              });
              // console.log("longestArray_: ", longestArray_);

              dataChart_labels.forEach(element1 => {
                longestArray_.data.forEach(element3 => {
                  if (element1.name !== longestArray_.name) {
                    let prueba = element1.data.find(item => item.x === element3.x);
                    // console.log("prueba: ", prueba);
                    if (!prueba) {
                      element1.data.push({ x: element3.x, y: 0, count: 0 });
                    }
                  }
                });
                element1.data = element1.data.sort(function (a, b) {
                  var keyA = new Date(a.x), keyB = new Date(b.x);
                  if (keyA > keyB) return 1;
                  if (keyA < keyB) return -1;
                  return 0;
                });
              });
              // console.log("dataChart_labels~: ", dataChart_labels);
              this.count_orders_date = countOrders;
              this.count_orders_complete_date = Number(this.ToFixed(countOrdersSales));
              this.refresh = true;

              this.showChart(this.obj, dataChart_labels);
            }
          }
        });
      });
    }, 300);

  }

  // changedTypeSearch(type) {
  //   this.count_orders_date = 0;
  //   this.count_orders_complete_date = 0;
  //   this.refresh = false;
  //   this.show_message_no_data = false;
  //   let num_days = 1;
  //   let limit_day = -1;

  //   switch (type) {
  //     case 1:
  //       this.btn_date.today.class = "btn btn-primary";
  //       this.btn_date.week.class = "btn btn-outline-primary";
  //       this.btn_date.month.class = "btn btn-outline-primary";
  //       this.btn_date.year.class = "btn btn-outline-primary";
  //       num_days = 0;
  //       limit_day = -1;

  //       break;
  //     case 2:
  //       this.btn_date.today.class = "btn btn-outline-primary";
  //       this.btn_date.week.class = "btn btn-primary";
  //       this.btn_date.month.class = "btn btn-outline-primary";
  //       this.btn_date.year.class = "btn btn-outline-primary";
  //       num_days = 7;
  //       limit_day = -1;

  //       break;
  //     case 3:
  //       this.btn_date.today.class = "btn btn-outline-primary";
  //       this.btn_date.week.class = "btn btn-outline-primary";
  //       this.btn_date.month.class = "btn btn-primary";
  //       this.btn_date.year.class = "btn btn-outline-primary";
  //       num_days = 30;
  //       limit_day = -1;

  //       break;
  //     case 4:
  //       this.btn_date.today.class = "btn btn-outline-primary";
  //       this.btn_date.week.class = "btn btn-outline-primary";
  //       this.btn_date.month.class = "btn btn-outline-primary";
  //       this.btn_date.year.class = "btn btn-primary";
  //       num_days = 365;
  //       limit_day = -1;

  //       break;
  //     default:
  //       break;
  //   }

  //   setTimeout(() => {
  //     let rows = 0;
  //     let dataChart_labels = [];
  //     this.commerce_data.forEach((commerce) => {
  //       let dataChart = [];
  //       let time = new Date();
  //       let countOrders = 0;
  //       let countOrdersSales = 0;
  //       let month = undefined;
  //       let count = 0;
  //       for (let day = limit_day; day < num_days; day++) {
  //         let indexDay = new Date(Date.UTC(time.getFullYear(), time.getMonth(), time.getDate() - day, 0, 0, 0));
  //         let ordersMatch = this.orders.filter((item1) => {
  //           let init_time = new Date(Date.UTC(70, 0, 1, 0, 0, 0, 0));
  //           init_time.setSeconds(item1['created_at']['seconds']);
  //           if (init_time.toLocaleDateString() == indexDay.toLocaleDateString() && commerce.uid === item1.commerce_uid) {
  //             countOrders++;
  //             countOrdersSales += item1.total;
  //             return true;
  //           } else {
  //             return false;
  //           }
  //         });
  //         let axisY = 0;
  //         if (ordersMatch.length > 0) {
  //           axisY = ordersMatch.length;
  //         }
  //         let format = indexDay.toDateString().split(" ");
  //         let format_;
  //         if (type === 4) {
  //           format_ = format[1] + " " + format[3];
  //           if (month === format[1] + " " + format[3]) {
  //             count += axisY;
  //           } else {
  //             if (count !== undefined && month !== undefined) {
  //               dataChart.push({ x: month, y: count, count: countOrders });
  //             }
  //             month = format[1] + " " + format[3];
  //             count = 0;
  //             count = axisY;
  //           }
  //         } else if (type === 3) {
  //           format_ = format[1] + " " + format[2] + " " + format[3];
  //           dataChart.push({ x: format_, y: axisY, count: countOrders });
  //         } else if (type === 1) {
  //           // console.log("ordersMatch: ", ordersMatch);
  //           if (ordersMatch.length > 0) {
  //             let hour1 = 0;
  //             let count = 1;
  //             let total_;
  //             ordersMatch.forEach((element, index) => {
  //               let init_time_ = new Date(Date.UTC(70, 0, 1, 0, 0, 0, 0));
  //               init_time_.setSeconds(element['created_at']['seconds']);
  //               let hour = init_time_.getHours();
  //               // console.log("format: ", init_time_.getHours())
  //               if (index === 0 || hour !== hour1) {
  //                 let other_format = indexDay.toDateString().split(" ");
  //                 let other_format_hour = init_time_.toTimeString().split(" ");
  //                 format_ = other_format[1] + " " + other_format[2] + " " + other_format[3] + " " + other_format_hour[0];
  //                 hour1 = hour;
  //                 count = 1;
  //                 dataChart.push({ x: format_, y: count, z: "$ " + element.total, count: countOrders });
  //               } else {
  //                 // console.log("index: ", dataChart.length - 1)
  //                 dataChart[dataChart.length - 1].y++;
  //                 total_ = dataChart[dataChart.length - 1].z.split(" ");
  //                 dataChart[dataChart.length - 1].z = "$ " + (Number(total_[1]) + element.total);
  //               }
  //             });
  //           }
  //         } else {
  //           format_ = format[0] + " " + format[1] + " " + format[2] + " " + format[3];
  //           dataChart.push({ x: format_, y: axisY, count: countOrders });
  //         }
  //       }

  //       if (type === 1) {
  //         let day_ = new Date();
  //         let day_format: any = new Date(day_.getFullYear(), (day_.getMonth()), day_.getDate(), 23, 59, 59);
  //         let hour_format_1: any = new Date(day_.getFullYear(), (day_.getMonth()), day_.getDate(), 0, 0, 0);
  //         const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };

  //         let format_1 = day_format.toLocaleDateString('es-ES', options);
  //         let format_2 = hour_format_1.toLocaleDateString('es-ES', options);

  //         this.date_from = format_1;
  //         this.date_up_to = format_2;
  //       } else {
  //         this.date_from = dataChart[0].x;
  //         this.date_up_to = dataChart[dataChart.length - 1].x;
  //       }

  //       this.count_orders_date += countOrders;
  //       this.count_orders_complete_date += Number(this.ToFixed(countOrdersSales));
  //       rows++;
  //       // console.log("dataChart: ", dataChart);
  //       if (dataChart.length !== 0 && dataChart[dataChart.length - 1].count !== 0) {
  //         dataChart_labels.push({
  //           name: "pedidos de " + commerce.name,
  //           data: dataChart
  //         });
  //       }
  //       if (rows === this.commerce_data.length) {
  //         this.revenueChartOptions = {};
  //         setTimeout(() => {
  //           this.refresh = true;
  //           this.count_orders_complete_date = Number(this.ToFixed(this.count_orders_complete_date));
  //           this.showChart(this.obj, dataChart_labels);
  //         }, 500);
  //       }
  //       // console.log("commerce_data: ", this.commerce_data.length);
  //       // console.log("rows: ", rows);
  //     });
  //   }, 300);
  // }

  /**
 * Revenue chart options
 */
  getRevenueChartOptions(obj: any) {

    this.firestore.getAll('commerces').subscribe((response) => {
      //  console.log("commerces: ",response)
      let activeCommerce = [];
      activeCommerce = response.filter(item => item.is_visible === true && item.zone && item.zone !== '');
      activeCommerce.forEach(uid_manager => {
        this.commerce_data.push({
          uid: uid_manager.uid,
          name: uid_manager.name,
          manager_user_uid: uid_manager.manager_user_uid
        })
      });
      // console.log("activeCommerce: ",activeCommerce)
      this.totalCommerce = activeCommerce.length;

      const today = this.calendar.getToday();
      let num_days;
      let limit_day;
      this.count_orders_date = 0;
      this.count_orders_complete_date = 0;
      this.refresh = false;
      this.show_message_no_data = false;
      let indexDate1;
      let indexDate2;

      num_days = this.calendar.getPrev(today, 'm', 1);
      limit_day = this.calendar.getToday();
      indexDate1 = new Date(num_days.year, num_days.month - 1, num_days.day, 0, 0);
      indexDate2 = new Date(limit_day.year, limit_day.month - 1, limit_day.day, 23, 59);

      setTimeout(() => {
        // console.log("indexDate1", indexDate1);
        // console.log("indexDate2", indexDate2);
        let dataChart_labels: any = [];
        let countOrders = 0;
        let countOrdersSales = 0;

        activeCommerce.forEach(async (uid_manager, index1) => {
          // console.log("uid_manager", uid_manager);
          await this.firestore.getWhere4('orders', 'manager_uid', uid_manager.manager_user_uid, 'orders_status_uid', 'Entregada', indexDate1, indexDate2).subscribe((res2) => {

            let dataChart = [];
            dataChart.length = 0;

            if (res2.length !== 0) {
              // console.log("uid_manager", uid_manager, " res2", res2);
              let groupedDates = {};
              countOrders += res2.length;

              res2.forEach((orders_, index) => {
                let initTime = new Date(Date.UTC(70, 0, 1, 0, 0, 0, 0));
                initTime.setSeconds(orders_['created_at']['seconds']);
                countOrdersSales += orders_.total;

                let format = initTime.toDateString().split(" ");
                let format_;

                format_ = format[1] + " " + format[2] + " " + format[3];

                if (!groupedDates[format_]) {
                  groupedDates[format_] = { x: format_, y: 0, count: 0 };
                }

                groupedDates[format_].y += 1;
                groupedDates[format_].count += Number(orders_.total);
                // groupedDates[format_].z = "$ " + (groupedDates[format_].count).toFixed(2);

                if (index === (res2.length - 1)) {
                  let day_format: any = new Date(indexDate1.getFullYear(), (indexDate1.getMonth()), indexDate1.getDate(), 23, 59, 59);
                  let hour_format_1: any = new Date(indexDate2.getFullYear(), (indexDate2.getMonth()), indexDate2.getDate(), 0, 0, 0);
                  let options;

                  options = { year: 'numeric', month: 'short', day: 'numeric' };

                  let format_1 = day_format.toLocaleDateString('es-ES', options);
                  let format_2 = hour_format_1.toLocaleDateString('es-ES', options);
                  this.date_from = format_1;
                  this.date_up_to = format_2;
                  // console.log("groupedDates: ", groupedDates);

                  // Agregar el objeto al array dataChart_labels sin modificarlo
                  let dataChartEntry = {
                    name: "pedidos de " + uid_manager.name,
                    data: Object.values(groupedDates) // Obtener los valores del objeto groupedDates
                  };
                  dataChart_labels.push(dataChartEntry);

                  if (index1 === (activeCommerce.length - 1)) {
                    let longestArray = 0;
                    let longestArray_: any;
                    dataChart_labels.forEach(element => {
                      if (element.data.length > longestArray) {
                        longestArray = element.data.length;
                        longestArray_ = element;
                      }
                    });
                    // console.log("longestArray_: ", longestArray_);

                    dataChart_labels.forEach(element1 => {
                      longestArray_.data.forEach(element3 => {
                        if (element1.name !== longestArray_.name) {
                          let prueba = element1.data.find(item => item.x === element3.x);
                          // console.log("prueba: ", prueba);
                          if (!prueba) {
                            element1.data.push({ x: element3.x, y: 0, count: 0 });
                          }
                        }
                      });
                      element1.data = element1.data.sort(function (a, b) {
                        var keyA = new Date(a.x), keyB = new Date(b.x);
                        if (keyA > keyB) return 1;
                        if (keyA < keyB) return -1;
                        return 0;
                      });
                    });
                    // console.log("dataChart_labels: ", dataChart_labels);
                    this.count_orders_date = countOrders;
                    this.count_orders_complete_date = Number(this.ToFixed(countOrdersSales));
                    this.refresh = true;

                    this.showChart(this.obj, dataChart_labels);
                  }
                }
              });

            } else {
              if (index1 === (activeCommerce.length - 1)) {
                let longestArray = 0;
                let longestArray_: any;
                dataChart_labels.forEach(element => {
                  if (element.data.length > longestArray) {
                    longestArray = element.data.length;
                    longestArray_ = element
                  }
                });
                // console.log("longestArray_: ", longestArray_);

                dataChart_labels.forEach(element1 => {
                  longestArray_.data.forEach(element3 => {
                    if (element1.name !== longestArray_.name) {
                      let prueba = element1.data.find(item => item.x === element3.x);
                      // console.log("prueba: ", prueba);
                      if (!prueba) {
                        element1.data.push({ x: element3.x, y: 0, count: 0 });
                      }
                    }
                  });
                  element1.data = element1.data.sort(function (a, b) {
                    var keyA = new Date(a.x), keyB = new Date(b.x);
                    if (keyA > keyB) return 1;
                    if (keyA < keyB) return -1;
                    return 0;
                  });
                });
                // console.log("dataChart_labels~: ", dataChart_labels);
                this.count_orders_date = countOrders;
                this.count_orders_complete_date = Number(this.ToFixed(countOrdersSales));
                this.refresh = true;

                this.showChart(this.obj, dataChart_labels);
              }
            }
          });
        });
      }, 300);
    }), ((e) => { console.log(e) });
  }

  showChart(obj, dataChart_labels) {
    this.refresh = true;
    // console.log("dataChart_labels: ", dataChart_labels)
    // console.log("orders: ",this.orders)
    if (dataChart_labels.length === 0) {
      this.show_message_no_data = true;
    } else {
      this.show_message_no_data = false;
      this.revenueChartOptions = {
        series: dataChart_labels,
        chart: {
          type: "line",
          height: '400',
          parentHeightOffset: 20,
          toolbar: {
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: true,
              zoomout: true,
              pan: false,
            }
          }
        },
        colors: [obj.primary, obj.secondary, obj.success, obj.info, obj.warning, obj.danger, obj.dark, obj.blue, obj.lime, obj.purple, obj.wine, obj.brown],
        grid: {
          padding: {
            bottom: -4,
          },
          borderColor: obj.gridBorder,
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        xaxis: {
          type: 'datetime',
          lines: {
            show: true
          },
          axisBorder: {
            color: obj.gridBorder,
          },
          axisTicks: {
            color: obj.gridBorder,
          },
          crosshairs: {
            stroke: {
              color: obj.secondary,
            },
          },
        },
        yaxis: {
          title: {
            text: 'Cantidad de pedidos',
            style: {
              size: 9,
              color: obj.muted
            }
          },
          tickAmount: 4,
          tooltip: {
            enabled: true
          },
          crosshairs: {
            stroke: {
              color: obj.secondary,
            },
          },
          labels: {
            offsetX: 0,
          },
        },
        markers: {
          size: 0,
        },
        stroke: {
          width: 2,
          curve: "smooth",
        },
      }
    }
  }
}
