import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';

import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
  ) { }

  // Get an object from Firestore by its path. For eg: firestore.get('users/' + userId) to get a user object.
  public get(path: string): Promise<AngularFirestoreDocument<{}>> {
    return new Promise(resolve => {
      resolve(this.afs.doc(path));
    });
  }

  // Check if the object exists on Firestore. Returns a boolean promise with true/false.
  public exists(path: string): Promise<boolean> {
    return new Promise(resolve => {
      this.afs.doc(path).valueChanges().pipe(take(1)).subscribe(res => {
        if (res) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  public getWhereWithRange<T>(collection, key1, value1, key2, value2, value3): Observable<any> {
    return this.afs
      .collection<T>(collection, ref => ref
        .where(key1, '==', value1)
        .orderBy(key2)
        .startAt(value2)
        .endAt(value3)
      )
      .valueChanges();
  }

  // Set the pushToken of the user given the userId.
  public setPushToken(userId: string, token: string): void {
    //this.removePushToken(userId);
    let time = new Date().getTime();
    let timestamp = new Date(time);
    this.get('user_push_tokens/' + userId).then(ref => {
      ref.set({
        pushToken: token,
        time: time,
        timestamp: timestamp,
      });
    }).catch((e) => { console.log(e); });
    this.StockLog('user_push_tokens', { pushToken: token, time: time, timestamp: timestamp }, 'Create');
  }

  public getOne<T>(collection, uid): Observable<any> {
    return this.afs.doc<T>(`${collection}/${uid}`).valueChanges();
  }

  public getOnePath<T>(path): Observable<any> {
    return this.afs.doc<T>(`${path}`).valueChanges();
  }

  public getOne2<T>(collection: string, uid: string): Observable<any> {
    return this.afs.doc<T>(`${collection}/${uid}`).get();
  }

  public getOneWatcher<T>(collection: string, uid: string): Observable<any> {
    return this.afs.doc<T>(`${collection}/${uid}`).valueChanges();
  }
  
  public getAllByDate<T>(collection: string): Observable<any> {
    return this.afs.collection<T>(collection, ref => ref.orderBy('timestamp', 'desc')).valueChanges();
  }

  public getAll<T>(collection: string): Observable<any> {
    return this.afs.collection<T>(collection).valueChanges({ idField: 'id' });
  }

  public getAllSubcollection<T>(collection: string, docID: string, subcollection: string): Observable<any> {
    return this.afs.collection<T>(collection).doc(docID).collection(subcollection).valueChanges({ idField: 'id' });
  }

  public getAllByDateCommerce<T>(collection: string, key3, value3): Observable<any> {
    return this.afs.collection<T>(collection, ref => ref.orderBy('created_at')
      .startAt(key3)
      .endAt(value3)).valueChanges({ idField: 'id' });
  }

  public getUid<T>(document) {
    return document.uid != null && document.uid != '' && document.uid != undefined ? document.uid : this.afs.createId();
  }

  public getWhere<T>(collection: string, key: string, value: any): Observable<any> {
    return this.afs.collection<T>(collection, ref => ref.where(key, '==', value)).valueChanges({ idField: 'id' });
  }

  public getWhereExist<T>(collection: string, key: string, value: any): Observable<any> {
    return this.afs.collection<T>(collection, ref => ref.where(key, '!=', value)).valueChanges({ idField: 'id' });
  }

  public get2Where<T>(collection: string, key: string, value: string, key2: string, value2: string): Observable<any> {
    return this.afs
      .collection<T>(collection, ref => ref
        .where(key, '==', value)
        .where(key2, '>=', value2)
        .where(key2, '<', value2)
        //.orderBy(key2)
        //.startAt(value2)
        //.endAt(value2+'\uf8ff')
      )
      .valueChanges({ idField: 'id' });
  }
  public getWhere2<T>(collection: string, key1: string | firebase.firestore.FieldPath, value1: any, key2: string | firebase.firestore.FieldPath, value2: any): Observable<any> {
    return this.afs
      .collection<T>(collection, ref => ref
        .where(key1, '==', value1)
        .where(key2, '==', value2)
        //.orderBy(key2)
        //.startAt(value2)
        //.endAt(value2+'\uf8ff')
      )
      .valueChanges({ idField: 'id' });
  }

  public getWhere2_1<T>(collection: string, key1: string | firebase.firestore.FieldPath, value1: any, key2: string | firebase.firestore.FieldPath, value2: any): Observable<any> {
    return this.afs
      .collection<T>(collection, ref => ref
        .where(key1, '==', value1)
        .where(key2, '!=', value2)
        //.orderBy(key2)
        //.startAt(value2)
        //.endAt(value2+'\uf8ff')
      )
      .valueChanges({ idField: 'id' });
  }

  public getWhere3<T>(collection, key1, value1, key2, value2, key3, value3): Observable<any> {

    return this.afs
      .collection<T>(collection, ref => ref
        .where(key1, '==', value1)
        .where(key2, '==', value2)
        .orderBy('created_at')
        .startAt(key3)
        .endAt(value3)
        //.endAt(value3+'\uf8ff')
      )
      .valueChanges({ idField: 'id' });
  }

  public getWhere4<T>(collection: string, key1: string | firebase.firestore.FieldPath, value1: any, key2: string | firebase.firestore.FieldPath, value2: any, date1: any, date2: any): Observable<any> {

    return this.afs
      .collection<T>(collection, ref => ref
        .where(key1, '==', value1)
        .where(key2, '==', value2)
        .orderBy('created_at')
        .startAt(date1)
        .endAt(date2)
        //.endAt(value3+'\uf8ff')
      )
      .valueChanges({ idField: 'id' });
  }

  public getWhere5<T>(collection: string, key1: string | firebase.firestore.FieldPath, value1: any, date1: any, date2: any): Observable<any> {

    return this.afs
      .collection<T>(collection, ref => ref
        .where(key1, '==', value1)
        .orderBy('created_at')
        .startAt(date1)
        .endAt(date2)
        //.endAt(value3+'\uf8ff')
      )
      .valueChanges({ idField: 'id' });
  }

  public getWhere2_3_1<T>(collection, key3, value3): Observable<any> {
    return this.afs
      .collection<T>(collection, ref => ref
        .orderBy('timestamp')
        .startAt(key3)
        .endAt(value3)
      )
      .valueChanges();
  }

  public getWhereByConditions<T>(
    collection: string,
    conditions: { key: string, operator: any, value: any }[],
    date1: any,
    date2: any
  ): Observable<any> {
    const queryFn = (ref: firebase.firestore.CollectionReference | firebase.firestore.Query) => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      conditions.forEach(condition => {
        query = query.where(condition.key, condition.operator, condition.value);
      });
      return query.orderBy('created_at')
        .startAt(date1)
        .endAt(date2);
    };

    return this.afs.collection<T>(collection, queryFn).snapshotChanges().pipe(
      switchMap(actions => {
        if (actions.length === 0) {
          // No se encontraron registros que cumplan con las condiciones
          return of([]);
        }

        const mappedActions = actions.map(a => {
          const data = a.payload.doc.data() as T;
          const uid = a.payload.doc.id;
          return { uid, ...data };
        });

        const observables = mappedActions.map(item => {
          return this.afs.doc<T>(`${collection}/${item.uid}`).valueChanges().pipe(
            map(data => ({ uid: item.uid, ...data }))
          );
        });

        return combineLatest(observables);
      })
    );
  }

  public getPagination<T>(collection: string): Observable<any> {
    return this.afs.collection<T>(collection, ref => ref.orderBy('created_at').limit(100)).valueChanges();
  }
  public getWherePagination<T>(collection: string, key: string, value: string): Observable<any> {
    return this.afs
      .collection<T>(collection, ref => ref
        .where(key, '>=', value)
        .where(key, '<=', value + '\uf8ff')
        .limit(10))
      .valueChanges();
  }
  public save<T>(collection: string, document: any): Promise<any> {
    this.StockLog(collection, document, 'Create');
    document.uid = document.uid != null && document.uid != '' && document.uid != undefined ? document.uid : this.afs.createId();
    return this.afs.doc<T>(`${collection}/${document.uid}`).set(document);
  }
  public saveSubcollection<T>(collection: string, docID: string, subcollection: string, subdocID: string, document: any): Promise<void> {
    if (!subdocID || subdocID == '') {
      subdocID = this.afs.createId();
      document.uid = subdocID;
    }
    this.StockLog(collection + "/" + docID + "/" + subcollection, document, 'Save');
    return this.afs.collection(collection).doc(docID).collection(subcollection).doc(subdocID).set(document, { merge: true });
  }
  public save2<T>(collection: string, uid: string, document: T): Promise<any> {
    this.StockLog(collection, document, 'Create');
    //document.uid = document.uid != null && document.uid != '' && document.uid != undefined ? document.uid : this.afs.createId();
    return this.afs.doc<T>(`${collection}/${uid}`).set(document);
  }

  public save3<T>(collection: string, document: any): Promise<any> {
    this.StockLog(collection, document, 'Create');
    document.user_uid = document.user_uid != null && document.user_uid != '' && document.user_uid != undefined ? document.user_uid : this.afs.createId();
    return this.afs.doc<T>(`${collection}/${document.user_uid}`).set(document);
  }

  public update<T>(collection: string, uid: string, document: any) {
    this.StockLog(collection, document, 'Update');
    return this.afs.collection(collection).doc(uid).update(document)
    //return this.afs.doc<T>(`${collection}/${uid}`).update(document);
  }

  public updateSubcollection<T>(collection: string, docID: string, subcollection: string, subdocID: string, document: any): Promise<void> {
    this.StockLog(collection + "/" + docID + "/" + subcollection, document, 'Update');
    return this.afs.collection(collection).doc(docID).collection(subcollection).doc(subdocID).update(document);
  }

  public update3<T>(collection: string, uid: string, document: T): Promise<any> {
    this.StockLog(collection, document, 'Update');
    return this.afs.doc<T>(`${collection}/${uid}`).update(document);
  }

  public update2(uid: string, document: any) {
    this.StockLog('commerces', document, 'Update');
    let db = firebase.firestore();
    let ref = db.collection('commerces').doc(uid);
    ref.update({
      'is_visible': document.is_visible,
      'is_available': document.is_available,
    }).then(() => {
      // console.log("UPDATE");
    }).catch(error => {
      // console.log(error);
    });
  }

  public async delete<T>(collection: string, uid: string): Promise<any> {
    await this.getOne(collection, uid).subscribe(res => {
      return this.StockLog(collection, res, 'Delete');
    })
    return this.afs.doc<T>(`${collection}/${uid}`).delete();
  }

  public async deleteSubcollection<T>(collection: string, docID: string, subcollection: string, subdocID: string): Promise<void> {
    await this.getOnePath(collection + "/" + docID + "/" + subcollection + "/" + subdocID).subscribe(res => {
      return this.StockLog(collection + "/" + docID + "/" + subcollection + "/" + subdocID, res, 'Delete');
    })
    return this.afs.collection(collection).doc(docID).collection(subcollection).doc(subdocID).delete();
  }

  public search(collectionName: string, objectSearch: any) {
    // console.log(objectSearch);

    /*
      After applying these query you may face this error:
      "ERROR FirebaseError: The query requires an index. You can create it here: URL"
      You will get above error with an URL - Click over that URL - Login in Firebase
      and this will prompt to Create an Index which is required in Firebase 
      to apply queries to Database Collection.
    */
    return this.afs.collection(collectionName, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      //for (const prop in objectSearch) { query = query.where(`${prop}`, '==', `${objectSearch[prop]}`); }
      if (objectSearch.phone) {
        query = query.where('phone', '==', objectSearch.phone);
      }
      if (objectSearch.salary) {
        query = query.where('salary', '>=', objectSearch.salary);
      }
      if (objectSearch.designation) {
        query = query.where('designation', '==', objectSearch.designation);
      }
      if (objectSearch.sucursal_uid) {
        query = query.where('sucursal_uid', '==', objectSearch.sucursal_uid);
      }
      if (objectSearch.commerce_uid && objectSearch.commerce_uid != '' && objectSearch.commerce_uid != undefined) {
        query = query.where('commerce_uid', '==', objectSearch.commerce_uid);
      }
      if (objectSearch.manager_user_uid && objectSearch.manager_user_uid != '' && objectSearch.manager_user_uid != undefined) {
        query = query.where('manager_user_uid', '==', objectSearch.manager_user_uid);
      }
      if (objectSearch.locations_uid && objectSearch.locations_uid != '' && objectSearch.locations_uid != undefined) {
        query = query.where('locations_uid', '==', objectSearch.locations_uid);
      }

      if (objectSearch.joinDate) {
        objectSearch.joinDateTimestamp = new Date(objectSearch.joinDate);
        query = query.where('joinDateTimestamp', '==', objectSearch.joinDateTimestamp);
      }
      // query = query.orderBy('joinDateTimestamp', 'desc');
      // query = query.orderBy('designation').limit(2);
      /* IMPORTANT: Reason I put this query at last because
       * We can not call Query.startAt() or Query.startAfter()
       * before calling Query.orderBy().
      */
      if (objectSearch.name) {
        query = query.where('name', '>=', objectSearch.name)
        query = query.where('name', '<=', objectSearch.name + '\uf8ff')

        // name starts with `An` then apply startAt('An')

        /* similar query `endAt`, `startAfter` and `endBefore`
            can be applied like this:
        */
        // query = query.endAt('An');
        // query = query.startAfter('An');
        // query = query.endBefore('An');
      }
      query = query.limit(objectSearch.limit)
      query = query.orderBy(objectSearch.orderByName, objectSearch.orderByDir);
      return query;
    }).snapshotChanges();
  }

  createUID() {
    return this.afs.createId();
  }

  public addCredit(amount: any, user_uid: string | undefined) {
    let credit = firebase.firestore().collection('credits').doc(user_uid).set({
      user_uid: user_uid,
      credits: amount,
      timestamp: new Date(),
    });
  }

  public updateArrayMessages<T>(collectionUid: string, document): Promise<any> {
    this.StockLog(collectionUid, document, 'Update');
    return this.afs.doc(`${collectionUid}`).update({
      messages: firebase.firestore.FieldValue.arrayUnion(document)
    });
  }

  public StockLog<T>(collection: string, document: any, action: string) {
    return new Promise((resolve) => {
      this.getUser().then((userLocal: any) => {
        // console.log('auth get() : ', ref);
        let data_log: any = {
          user_uid: userLocal.uid,
          email: userLocal.email,
          uid: this.afs.createId(),
          action: action,
          collection: collection,
          created_date: new Date(),
          data_info: JSON.stringify(document),
        }
        this.afs.doc<T>(`stock_log/${data_log.uid}`).set(data_log);
        resolve(data_log);
      }).catch((e) => {
      });
    });
  }

  private getUser() {
    return new Promise((resolve) => {
      this.afAuth.authState.subscribe((userFirebase: any) => {
        if (userFirebase) {
          this.get('users/' + userFirebase.uid).then(ref => {
            // console.log('auth get() : ', ref);
            ref.valueChanges().subscribe((userLocal: any) => {
              resolve(userLocal);
            });
          }).catch((e) => {
          });
        }
      });
    });
  }
}

