export class Verification {
  public uid: string = '';
  public user_uid: string = '';
  public issue_list: Array<{ description: string }> = [];

  public data_complete: boolean = false;
  public data_checking: boolean = false;
  public data_approved: boolean = false;
  public data_comment: string = '';

  public dui_complete: boolean = false;
  public dui_checking: boolean = false;
  public dui_approved: boolean = false;
  public dui_comment: string = '';
  public dui_photo_front: string = '';
  public dui_photo_back: string = '';

  public driver_license_complete: boolean = false;
  public driver_license_checking: boolean = false;
  public driver_license_approved: boolean = false;
  public driver_license_comment: string = '';
  public license_photo_front: string = '';
  public license_photo_back: string = '';

  public circulation_card_complete: boolean = false;
  public circulation_card_checking: boolean = false;
  public circulation_card_approved: boolean = false;
  public circulation_card_comment: string = '';
  public circulation_photo_front: string = '';
  public circulation_photo_back: string = '';

  public pnc_solvency_complete: boolean = false;
  public pnc_solvency_checking: boolean = false;
  public pnc_solvency_approved: boolean = false;
  public pnc_solvency_comment: string = '';
  public pnc_photo_front: string = '';
  public pnc_photo_back: string = '';

  public criminal_solvency_complete: boolean = false;
  public criminal_solvency_checking: boolean = false;
  public criminal_solvency_approved: boolean = false;
  public criminal_solvency_comment: string = '';
  public criminal_photo_front: string = '';
  public criminal_photo_back: string = '';

  public progress_percent: number = 15;

  public user_photo: string = '';
  public photo_complete: boolean = false;
  public photo_checking: boolean = false;
  public photo_approved: boolean = false;
  public photo_comment: string = '';

  public vehicle_complete: boolean = false;
  public vehicle_checking: boolean = false;
  public vehicle_approved: boolean = false;
  public vehicle_comment: string = '';

  public payment_complete: boolean = false;
  public payment_checking: boolean = false;
  public payment_approved: boolean = false;
  public payment_comment: string = '';

  public schedule_complete: boolean = false;
  public schedule_checking: boolean = false;
  public schedule_approved: boolean = false;
  public schedule_comment: string = '';
  constructor() { }
}
