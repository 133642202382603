import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavService, Menu } from '../../service/nav.service';
import { AuthService } from '../../service/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirestoreService } from '../../service/firestore/firestore.service';
import { User } from '../../models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  public menuItems: Menu[];
  public menuItems_data: Menu[];
  public url: any;
  public fileurl: any;
  user = new User();
  displayName: string = '';
  photoURL: string = '';
  sub1: Subscription;

  constructor(
    private router: Router, 
    public navServices: NavService,
    public auth: AuthService,
    private afAuth: AngularFireAuth,
    private firestore: FirestoreService,
    ) {
      firestore.getAll('nav_menu_permissions').subscribe(res => {
        // console.log("nav_menu_permissions: ",res);
        // this.MENUITEMS = res;
        res = res.sort(function (a, b) {
          var keyA = a.order, keyB = b.order;
          if (keyA > keyB) return 1;
          if (keyA < keyB) return -1;
          return 0;
        });
        res = res.filter( items => !items.hide);
        this.menuItems_data = res;
        this.loadAlerts().then(res1 => {
          this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              this.menuItems_data.filter(items => {
                if (items.path === event.url)
                  this.setNavActive(items)
                if (!items.children) return false
                items.children.filter(subItems => {
                  if (subItems.path === event.url)
                    this.setNavActive(subItems)
                  if (!subItems.children) return false
                  subItems.children.filter(subSubItems => {
                    if (subSubItems.path === event.url)
                      this.setNavActive(subSubItems)
                  })
                })
              })
            }
          })
        });
      })
    // this.navServices.items.subscribe(menuItems => {
    // })
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }
  
  ngOnInit(): void {
  }

  loadAlerts() {
    return new Promise((resolve) => {
      if (this.sub1) { this.sub1.unsubscribe(); }
      this.afAuth.authState.subscribe((userFirebase: any) => {
        if (userFirebase) {
          // console.log('auth getUserFirebase() : ', userFirebase.uid);
          this.firestore.get('users/' + userFirebase.uid).then(ref => {
            // console.log('auth get() : ', ref);
            ref.valueChanges().subscribe((userLocal: any) => {
              // console.log('auth getUserFirestore() : ', userLocal);
              this.user = userLocal;
              this.displayName = userLocal.who_receives!=undefined&&userLocal.who_receives!=null&&userLocal.who_receives!='' ? userLocal.who_receives :'';
              this.photoURL = userLocal.photoURL!=undefined&&userLocal.photoURL!=null&&userLocal.photoURL!='' ? userLocal.photoURL :'../assets/img/profile.png';
  
              if (this.user.is_super) {
                this.menuItems = this.menuItems_data;
                resolve(this.menuItems);
              }else{
                let menuItems = [];
                this.menuItems_data.forEach((element: any,index) => {
                  this.firestore.getOnePath('nav_menu_permissions/'+element.id+'/users/'+this.user.uid).subscribe(res1 => {
                    // console.log('nav_menu_permissions user: ', res1);
                    if (res1 !== undefined) {
                      if (element.type === 'sub') {
                        let new_children = [];
                        element.children.forEach((children1,index) => {
                          let path = (children1.path).replace(new RegExp('/', 'g'), '');
                          if (res1.hasOwnProperty(path) && res1[path].view === true) {
                            new_children.push(children1);
                          }
                          if (index === (element.children.length - 1)) {
                            element.children = new_children;
                            if (new_children.length !== 0) {
                              menuItems.push(element);
                            }
                          }
                        });
                      }else{
                        if (res1.view === true) {
                          menuItems.push(element);
                        }
                      }
                    }
                    if (index === (this.menuItems_data.length - 1)) {
                      this.menuItems = menuItems;
                      resolve(this.menuItems);
                    }
                  })
                });
              }
            });
          }).catch((e) => {
          });
        } 
      });
    })
  }

}
