import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavService } from '../../service/nav.service';
import { AuthService } from '../../service/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirestoreService } from '../../service/firestore/firestore.service';
import { EventsService } from '../../service/events/events.service';
import { FirestoreservicesService } from '../../service/firestoreservices/firestoreservices.service';
import { Router } from '@angular/router';
import { User } from '../../models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile : boolean;
  user = new User();
  displayName: string = '';
  photoURL: string = '';
  sub1: Subscription;
  countAlert = 0;
  order: any [] = [];
  alertColor = "warning";

  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(
    public navServices: NavService,
    private router: Router,
    public auth: AuthService,
    private afAuth: AngularFireAuth,
    private firestore: FirestoreService,
    public events: EventsService,
    public ordersDB: FirestoreservicesService,
  ) { }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }
  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }


  ngOnInit(): void {
    this.loadAlerts();
    let this_ = this;
    this.events.subscribe('alert:changeColor', (data) => {
      if (data['change']) {
        this.alertColor = this_.alertColor == "warning" ? "danger" : "warning";
        //console.log(this.alertColor);
        //this.changeColor();
      } else {
        //clearInterval(this.intervalSound); 
        this.alertColor = "warning";
      }
    });
  }

  loadAlerts() {
    if (this.sub1) { this.sub1.unsubscribe(); }
    this.afAuth.authState.subscribe((userFirebase: any) => {
      if (userFirebase) {
        // console.log('auth getUserFirebase() : ', userFirebase.uid);
        this.firestore.get('users/' + userFirebase.uid).then(ref => {
          // console.log('auth get() : ', ref);
          ref.valueChanges().subscribe((userLocal: any) => {
            // console.log('auth getUserFirestore() : ', userLocal);
            this.user = userLocal;
            this.displayName = userLocal.who_receives!=undefined&&userLocal.who_receives!=null&&userLocal.who_receives!='' ? userLocal.who_receives :'';
            this.photoURL = userLocal.photoURL!=undefined&&userLocal.photoURL!=null&&userLocal.photoURL!='' ? userLocal.photoURL :'../assets/img/profile.png';
            // this.ordersDB.getAlertsAsync(userLocal.uid,'orders').subscribe(res2 => {
            //   if (res2.length > 0) {
            //     let news = res2.filter(item => item['orders_status_uid'] == 'Creada');
            //     if (news.length > 0) {
            //       this.order = news;
            //       // console.log("order: ", this.order);
            //       this.setCountAlert(news.length);
            //       this.events.publish('main:alert', { from: 'header', loadAlert: true, alerts: news });
            //       this.events.publish('alert:sound', { from: 'header', sound: true });
            //     } else {
            //       this.countAlert = 0;
            //       this.events.publish('alert:sound', { from: 'header', sound: false });
            //     }
            //   }
            // }, (e) => { console.log(e) });
          });
        }).catch((e) => {
        });
      } 
    });
  }

  alert() {
    // console.log('alert publish')
    this.events.publish('main:alert', { 'openAlert': true });
    this.events.publish('alert:sound', { from: 'header', sound: false });
  }

  setCountAlert(val1) {
    if (this.countAlert != val1) {
      this.countAlert = val1;
    }
  }

  /**
   * Logout
   */
   onLogout(e: Event) {
    this.auth.logout().then((e) => {
      // console.log(e);
      // console.log('SignOut()');
      this.router.navigate(['/auth/login']);
    }).catch((e) => {console.log(e);});
  }

  GotoOrders(id){
    this.router.navigate(['order-show/'+id]);
  }

  Goto(id){
    this.router.navigate([id]);
  }
}
