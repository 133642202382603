<div class="page-main-header" [class.open]="navServices.collapseSidebar">
    <div class="main-header-right row">
        <div class="main-header-left d-lg-none col">
            <div class="logo-wrapper">
                <!-- <a [routerLink]="'/dashboard/default'"><img class="blur-up lazyloaded"
                        src="assets/images/dashboard/multikart-logo.png" alt=""></a> -->
                <a [routerLink]="'/dashboard/default'" class="nobleui-logo" *ngIf="user.is_enabled === true">
                    YELOU<span><strong>.APP</strong></span>
                </a>
                <div class="nobleui-logo" *ngIf="user.is_enabled !== true">
                    YELOU<span><strong>.APP</strong></span>
                </div>
            </div>
        </div>
        <div class="mobile-sidebar col">
            <div class="media-body switch-sm">
                <label class="switch">
                    <a>
                        <app-feather-icons id="sidebar-toggle" [icon]="'align-left'" (click)="collapseSidebar()">
                        </app-feather-icons>
                    </a>
                </label>
            </div>
        </div>
        <div class="nav-right col">
            <ul class="nav-menus" [ngClass]="{'open' : openNav}" style="display: flex; justify-content: flex-end; padding-right: 10px;">
                <!-- <li>
                    <form class="form-inline search-form">
                        <div class="form-group">
                            <input class="form-control-plaintext" [class.open]="isOpenMobile" type="search"
                                placeholder="Search..">
                            <span class="d-sm-none mobile-search">
                                <app-feather-icons [icon]="'search'" (click)="isOpenMobile=!isOpenMobile">
                                </app-feather-icons>
                            </span>
                        </div>
                    </form>
                </li> -->
                <li>
                    <a href="javascript:void(0)" class="text-dark" toggleFullscreen>
                        <app-feather-icons [icon]="'maximize-2'"></app-feather-icons>
                    </a>
                </li>
                <!-- <li class="onhover-dropdown"><a class="txt-dark" href="javascript:void(0)">
                        <h6>EN</h6>
                    </a>
                    <ul class="language-dropdown onhover-show-div p-20">
                        <li><a href="javascript:void(0)" data-lng="en"><i class="flag-icon flag-icon-is"></i>
                                English</a></li>
                        <li><a href="javascript:void(0)" data-lng="es"><i class="flag-icon flag-icon-um"></i>
                                Spanish</a></li>
                        <li><a href="javascript:void(0)" data-lng="pt"><i class="flag-icon flag-icon-uy"></i>
                                Portuguese</a></li>
                        <li><a href="javascript:void(0)" data-lng="fr"><i class="flag-icon flag-icon-nz"></i> French</a>
                        </li>
                    </ul>
                </li> -->
                <!-- <li class="onhover-dropdown" (click)="alert()" *ngIf="user.is_enabled === true">
                    <span class="badge badge-pill badge-primary pull-right notification-badge"
                        *ngIf="countAlert !== 0">{{countAlert}}</span>
                    <app-feather-icons [icon]="'bell'"></app-feather-icons><span class="dot"
                        *ngIf="countAlert !== 0"></span>
                    <ul class="notification-dropdown onhover-show-div">
                        <li># de ordenes creadas <span
                                class="badge badge-pill badge-primary pull-right">{{countAlert}}</span></li>
                        <li *ngFor="let item of order; let i = index">
                            <a href="javascript:void(0)" (click)="GotoOrders(item.uid)" style="width: 250px;">
                                <div class="media">
                                    <div class="media-body">
                                        <h6 class="mt-0"><span>
                                                <app-feather-icons class="shopping-color" [icon]="'package'">
                                                </app-feather-icons>
                                            </span>{{item.uid}}
                                        </h6>
                                        <p class="mb-0">cliente: {{item.who_receives}}</p>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="txt-dark"><a [routerLink]="'orders'">Ver todas</a> las ordenes</li>
                    </ul>
                </li> -->
                <li class="onhover-dropdown">
                    <div class="media align-items-center"><img
                            class="align-self-center pull-right img-50 rounded-circle" [src]="photoURL" style="object-fit: cover; max-width: 55px; max-height: 55px; min-width: 55px; min-height: 55px;"
                            alt="header-user">

                        <!-- <div class="dotted-animation"><span class="animate-circle"></span><span
                                class="main-circle"></span></div> -->
                    </div>
                    <ul class="profile-dropdown onhover-show-div p-20 profile-dropdown-hover" style="width: 250px;">
                        <li><a [routerLink]="'/settings/profile'">
                                <app-feather-icons [icon]="'user'"></app-feather-icons>Perfil
                            </a></li>
                        <!-- <li><a [routerLink]="'/verification'">
                                <app-feather-icons [icon]="'edit'"></app-feather-icons>Formulario de verificación
                            </a></li> -->
                        <li>
                            <a href="javascript:void(0)" (click)="onLogout($event)">
                                <app-feather-icons [icon]="'log-out'"></app-feather-icons>Cerrar Sesión
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
                <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
            </div>
        </div>
    </div>
</div>