import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../service/auth/auth.service';
import { FirestoreService } from '../../service/firestore/firestore.service';

@Injectable()
export class AuthGuard implements CanActivate {
  sub1: Subscription;

  constructor(
    private router: Router,
    private auth: AuthService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(resolve => {
      if (this.sub1) { this.sub1.unsubscribe(); }
      this.auth.getUser2().then(userLocal => {
        // console.log(userLocal)
        resolve(true);
      }, (e) => {
        console.log(e);
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
        resolve(false);
      });
    });

  }
}