import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from '../../models';
import { Observable, Subscription } from 'rxjs';
import { EventsService } from '../events/events.service';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private fbSubscription: Subscription;
  private fsSubscription: Subscription;
  public user = new User();

  constructor(
    private afAuth: AngularFireAuth,
    public events: EventsService,
    private firestore: FirestoreService,
  ) { }

  public getUser2(): Promise<User> {
    return new Promise((resolve, reject) => {
      if (this.fbSubscription) { this.fbSubscription.unsubscribe(); }
      this.fbSubscription = this.afAuth.authState.subscribe((userFirebase: any) => {
        if (userFirebase) {
          if (this.user && this.user.uid === userFirebase.uid) {
            // console.log('auth getUserLocal() : ', this.user);
            resolve(this.user)
          } else {
            // console.log('auth getUserFirebase() : ', userFirebase.uid);
            this.firestore.get('users/' + userFirebase.uid).then(ref => {
              // console.log('auth get() : ', ref);
              if (this.fsSubscription) { this.fsSubscription.unsubscribe(); }
              this.fsSubscription = ref.valueChanges().subscribe((userLocal: any) => {
                // console.log('auth getUserFirestore() : ', userLocal);
                if (userLocal) {
                  this.user = userLocal;
                  this.user.id = userFirebase.uid;
                  resolve(this.user);
                }else{
                  reject('Undifined data');
                }
              });
            }).catch((e) => {
              reject(e);
            });
          }
        } else {
          this.fbSubscription.unsubscribe();
          reject(false);
        }
      });
    });
  }

  public registerWithEmail(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.createUserWithEmailAndPassword(email, password).then(res => {
        console.log('1 - createUserWithEmailAndPassword res: ', res);
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  }

  public loginWithEmail(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signInWithEmailAndPassword(email, password).then(res => {
        /*    JSON.stringify(res);  */
        resolve(res);
        console.log('loginWithEmail', res);
      }).catch(err => {
        reject(err);
      });
    });
  }

  public sendEmailVerification(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe((user: any) => {
        user.sendEmailVerification().then((res: any) => {
          resolve(res);
        }).catch((error: any) => {
          reject(error);
        });
      });
    });
  }

  // Log the user out from Firebase.
  public logout(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signOut().then((e) => {
        localStorage.removeItem('user');
        localStorage.removeItem('orders');
        localStorage.removeItem('lastBuy');
        localStorage.removeItem('session_type');
        localStorage.removeItem('uid');
        localStorage.removeItem('isLoggedin');
        this.events.publish('session-start', false);
        this.events.publish('user:session', false);
        this.user = new User();
        // console.log(e);
        // this.user = null;
        // console.log('logout() true');
        resolve(true);
      }).catch((e) => {
        // console.log(e);
        // console.log('logout() false');
        reject(e);
      });
    });
  }

  // Reset password of the logged in user on Firebase.
  public resetPassword(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.sendPasswordResetEmail(email).then((res) => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  }
}
