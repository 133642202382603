import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'products',
    loadChildren: () => import('../../components/products/products.module').then(m => m.ProductsModule),
    data: {
      breadcrumb: "Products"
    }
  },
  {
    path: 'sales',
    loadChildren: () => import('../../components/sales/sales.module').then(m => m.SalesModule),
    data: {
      breadcrumb: "Sales"
    }
  },
  {
    path: 'coupons',
    loadChildren: () => import('../../components/coupons/coupons.module').then(m => m.CouponsModule),
    data: {
      breadcrumb: "Coupons"
    }
  },
  {
    path: 'pages',
    loadChildren: () => import('../../components/pages/pages.module').then(m => m.PagesModule),
    data: {
      breadcrumb: "Pages"
    }
  },
  {
    path: 'media',
    loadChildren: () => import('../../components/media/media.module').then(m => m.MediaModule),
  },
  {
    path: 'menus',
    loadChildren: () => import('../../components/menus/menus.module').then(m => m.MenusModule),
    data: {
      breadcrumb: "Menus"
    }
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: "Users"
    }
  },
  {
    path: 'vendors',
    loadChildren: () => import('../../components/vendors/vendors.module').then(m => m.VendorsModule),
    data: {
      breadcrumb: "Vendors"
    }
  },
  {
    path: 'localization',
    loadChildren: () => import('../../components/localization/localization.module').then(m => m.LocalizationModule),
    data: {
      breadcrumb: "Localization"
    }
  },
  {
    path: 'reports2',
    loadChildren: () => import('../../components/reports/reports.module').then(m => m.ReportsModule),
  },
  {
    path: 'orders',
    loadChildren: () => import('../../components/orders/orders.module').then(m => m.OrdersModule),
  },
  {
    path: 'order-show/:id',
    loadChildren: () => import('../../components/order-show/order-show.module').then(m => m.OrderShowModule),
    data: {
      breadcrumb: "Pagos"
    }
  },
  {
    path: 'payments',
    loadChildren: () => import('../../components/payments/payments.module').then(m => m.PaymentsModule),
  },
  {
    path: 'stores',
    loadChildren: () => import('../../components/stores/stores.module').then(m => m.StoresModule),
  },
  {
    path: 'affiliates',
    loadChildren: () => import('../../components/affiliates/affiliates.module').then(m => m.AffiliatesModule),
  },
  {
    path: 'yets',
    loadChildren: () => import('../../components/yets/yets.module').then(m => m.YetsModule),
  },
  {
    path: 'yet-show/:id',
    loadChildren: () => import('../../components/yet-show/yet-show.module').then(m => m.YetShowModule),
    data: {
      breadcrumb: "Yets"
    }
  },
  {
    path: 'local-show/:id',
    loadChildren: () => import('../../components/local-show/local-show.module').then(m => m.LocalShowModule),
    data: {
      breadcrumb: "Local"
    }
  },
  {
    path: 'clients',
    loadChildren: () => import('../../components/clients/clients.module').then(m => m.ClientsModule),
  },

  {
    path: 'client-show/:id',
    loadChildren: () => import('../../components/client-show/client-show.module').then(m => m.ClientShowModule),
    data: {
      breadcrumb: "Clientes"
    }
  },
  {
    path: 'affiliated-show/:id',
    loadChildren: () => import('../../components/affiliated-show/affiliated-show.module').then(m => m.AffiliatedShowModule),
    data: {
      breadcrumb: "Afiliado"
    }
  },
  {
    path: 'yellow-go-details/:id',
    loadChildren: () => import('../../components/yellow-go/yellow-go-details/yellow-go-details.module').then(m => m.YellowGoDetailsModule),
    data: {
      breadcrumb: "Parametrización"
    }
  },
  {
    path: 'commerces-control/:id',
    loadChildren: () => import('../../components/commerces-control/commerces-control.module').then(m => m.CommercesControlModule),
    data: {
      breadcrumb: "Locales"
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('../../components/setting/setting.module').then(m => m.SettingModule),
    data: {
      breadcrumb: "Settings"
    }
  },
  {
    path: 'issues',
    loadChildren: () => import('../../components/issues/issues.module').then(m => m.IssuesModule),
  },
  {
    path: 'products/:id',
    loadChildren: () => import('../../components/commerces-control/products/products.module').then(m => m.ProductsModule),
    data: {
      breadcrumb: "Locales",
    }
  },
  {
    path: 'details/:id',
    loadChildren: () => import('../../components/cash-cuts/details/details.module').then(m => m.DetailsModule),
    data: {
      breadcrumb: "Corte de caja",
    }
  },
  {
    path: 'verification',
    loadChildren: () => import('../../components/verification/verification.module').then(m => m.VerificationModule),
  },
  {
    path: 'form-claim',
    loadChildren: () => import('../../components/form-claim/form-claim.module').then(m => m.FormClaimModule),
  },
  {
    path: 'admin-zone',
    loadChildren: () => import('../../components/admin-zone/admin-zone.module').then(m => m.AdminZoneModule),
  },
  {
    path: 'add-admin-zona/:id',
    loadChildren: () => import('../../components/admin-zone/add-admin-zona/add-admin-zona.module').then(m => m.AddAdminZonaModule),
    data: {
      breadcrumb: "Administrador de Zonas"
    }
  },
  {
    path: 'commerce-admin-zone/:id',
    loadChildren: () => import('../../components/admin-zone/commerce-admin-zone/commerce-admin-zone.module').then(m => m.CommerceAdminZoneModule),
    data: {
      breadcrumb: "Administrador de Zonas"
    }
  },
  {
    path: 'yellow-go',
    loadChildren: () => import('../../components/yellow-go/yellow-go.module').then(m => m.YellowGoModule),
  },
  {
    path: 'permissions-users',
    loadChildren: () => import('../../components/permissions-users/permissions-users.module').then(m => m.PermissionsUsersModule),
  },
  {
    path: 'crypto',
    loadChildren: () => import('../../components/crypto/crypto.module').then(m => m.CryptoModule),
  },
  {
    path: 'reports',
    loadChildren: () => import('../../components/issues-yelou-app/reports/reports.module').then(m => m.ReportsModule),
    data: {
      breadcrumb: "Informes Yelou App"
    }
  },
  {
    path: 'seles-report',
    loadChildren: () => import('../../components/issues-yelou-app/seles-report/seles-report.module').then(m => m.SelesReportModule),
    data: {
      breadcrumb: "Informes Yelou App"
    }
  },
  {
    path: 'cost-membership',
    loadChildren: () => import('../../components/issues-yelou-app/cost-membership/cost-membership.module').then(m => m.CostMembershipModule),
    data: {
      breadcrumb: "Informes Yelou App"
    }
  },
  {
    path: 'sales-by-commerce',
    loadChildren: () => import('../../components/issues-yelou-app/sales-by-commerce/sales-by-commerce.module').then(m => m.SalesByCommerceModule),
    data: {
      breadcrumb: "Informes Yelou App"
    }
  },
  {
    path: 'expense-report',
    loadChildren: () => import('../../components/issues-yelou-app/expense-report/expense-report.module').then(m => m.ExpenseReportModule),
    data: {
      breadcrumb: "Informes Yelou App"
    }
  },
  {
    path: 'earnings-report',
    loadChildren: () => import('../../components/issues-yelou-app/earnings-report/earnings-report.module').then(m => m.EarningsReportModule),
    data: {
      breadcrumb: "Informes Yelou App"
    }
  },
  {
    path: 'product-add',
    loadChildren: () => import('../../components/yellow-go/product-add/product-add.module').then(m => m.ProductAddModule),
    data: {
      breadcrumb: "Parametrización"
    }
  },
  {
    path: 'categorie-add',
    loadChildren: () => import('../../components/yellow-go/categorie-add/categorie-add.module').then(m => m.CategorieAddModule),
    data: {
      breadcrumb: "Parametrización"
    }
  },
  {
    path: 'ingredient-add',
    loadChildren: () => import('../../components/yellow-go/ingredient-add/ingredient-add.module').then(m => m.IngredientAddModule),
    data: {
      breadcrumb: "Parametrización"
    }
  },
  {
    path: 'invoice',
    loadChildren: () => import('../../components/invoice/invoice.module').then(m => m.InvoiceModule),
    data: {
      breadcrumb: "Invoice"
    }
  },
  {
    path: 'payments-yellow-go',
    loadChildren: () => import('../../components/issues-yellow-go/payments-yellow-go/payments-yellow-go.module').then(m => m.PaymentsYellowGoModule),
    data: {
      breadcrumb: "Informes Yellow Go"
    }
  },
  {
    path: 'reinstatement-affiliate',
    loadChildren: () => import('../../components/reinstatement-affiliate/reinstatement-affiliate.module').then(m => m.ReinstatementAffiliateModule),
    data: {
      breadcrumb: "Reintegro a Afiliados"
    }
  },
  {
    path: 'sale-summary',
    loadChildren: () => import('../../components/issues-yellow-go/sale-summary/sale-summary.module').then(m => m.SaleSummaryModule),
    data: {
      breadcrumb: "Informes Yellow Go"
    }
  },
  {
    path: 'sales-by-item',
    loadChildren: () => import('../../components/issues-yellow-go/sales-by-item/sales-by-item.module').then(m => m.SalesByItemModule),
    data: {
      breadcrumb: "Informes Yellow Go"
    }
  },
  {
    path: 'sales-by-category',
    loadChildren: () => import('../../components/issues-yellow-go/sales-by-category/sales-by-category.module').then(m => m.SalesByCategoryModule),
    data: {
      breadcrumb: "Informes Yellow Go"
    }
  },
  {
    path: 'sales-by-type-pay',
    loadChildren: () => import('../../components/issues-yellow-go/sales-by-type-pay/sales-by-type-pay.module').then(m => m.SalesByTypePayModule),
    data: {
      breadcrumb: "Informes Yellow Go"
    }
  },
  {
    path: 'sales-report-emmi',
    loadChildren: () => import('../../components/issues-yellow-go/sales-report-emmi/sales-report-emmi.module').then(m => m.SalesReportEmmiModule),
    data: {
      breadcrumb: "Informes Yellow Go"
    }
  },
  {
    path: 'stock-history',
    loadChildren: () => import('../../components/stock-history/stock-history.module').then(m => m.StockHistoryModule),
  },
  {
    path: 'cash-cuts',
    loadChildren: () => import('../../components/cash-cuts/cash-cuts.module').then(m => m.CashCutsModule),
  },
  {
    path: 'kitchen-order-suggestion',
    loadChildren: () => import('../../components/kitchen-order-suggestion/kitchen-order-suggestion.module').then(m => m.KitchenOrderSuggestionModule),
  },
  {
    path: 'promotions',
    loadChildren: () => import('../../components/promotions/promotions.module').then(m => m.PromotionsModule),
    data: {
      breadcrumb: "Promociones"
    }
  },
  {
    path: 'sales-taxes',
    loadChildren: () => import('../../components/issues-yellow-go/sales-taxes/sales-taxes.module').then(m => m.SalesTaxesModule),
    data: {
      breadcrumb: "Informes Yellow Go"
    }
  }
];